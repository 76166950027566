import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getOmnivaLocations, reset } from '../features/shipping/shippingSlice';
import { toast } from 'react-toastify';

function OmnivaSelect({ handleSelectPickup }) {
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);

  const { omniva_locations, isLoading, isError, message } = useSelector((state) => state.shipping);

  const [searchTerm, setSearchTerm] = useState('');
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    if (omniva_locations) {
      setSearchTerm('');
    }

    return () => {
      dispatch(reset());
    };
  }, [omniva_locations, isError, message, dispatch]);

  const handleValueChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredLocations = omniva_locations?.filter((location) =>
    location.NAME[0].toLowerCase().includes(searchTerm)
  );

  useEffect(() => {
    dispatch(getOmnivaLocations());
  }, [dispatch]);

  const handleFocus = () => {
    setIsDropdownVisible(true);
    setSearchTerm('');
  };

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if(isLoading) { return <div>...</div> }

  return (
    <div className="flex column omniva-select padding-20-bottom">
      <input
        type="text"
        placeholder="Izvēlies sev tuvāko Omniva pakomātu..."
        value={searchTerm}
        onChange={handleValueChange}
        onFocus={handleFocus}
        className="padding-10"
      />
      {isDropdownVisible && (
        <div className="locations-dropdown" ref={dropdownRef}>
          {filteredLocations?.map((location, index) => (
            <div
              key={index}
              onMouseDown={() => {
                handleSelectPickup(location);
                setIsDropdownVisible(false);
                setSearchTerm(location.NAME[0]);
              }}
              className="location padding-10"
            >
              {location.NAME}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default OmnivaSelect;
