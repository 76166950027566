import axios from 'axios'

const API_URL = '/questions/'

// Get all questions
const getQuestions = async () => {
    const response = await axios.get(API_URL)
    return response.data
}

// Get question by ID
const getQuestionById = async (token, id) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await axios.get(API_URL + id, config)
    return response.data
}

// Create a question
const createQuestion = async (token, question) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await axios.post(API_URL, question, config)
    return response.data
}

// Update a question
const updateQuestion = async (token, question) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await axios.put(API_URL + question._id, question, config)
    return response.data
}

// Delete a question
const deleteQuestion = async (token, id) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await axios.delete(API_URL + id, config)
    return response.data
}

const questionService = {
    getQuestions,
    getQuestionById,
    createQuestion,
    updateQuestion,
    deleteQuestion
}
  
export default questionService
