import { useState, useEffect } from 'react';
import ButtonPrimary from "./style-components/ButtonPrimary";
import { toast } from 'react-toastify';

function ProductContent({ product, handleAddToCart }) {
  // Automatically select the first variant
  const [selectedVariant, setSelectedVariant] = useState(product.variants[0]);

  const handleVariantChange = (event) => {
      const variantId = event.target.value;
      const variant = product.variants.find(v => v._id === variantId);
      setSelectedVariant(variant);
    };

  const cartHandler = () => {
    if (!selectedVariant) {
      toast.error('Lūdzu izvēlies izmēru!');
    } else {
      const product_object = { ...product, variant: selectedVariant };
      handleAddToCart(product_object);
    }
  }

  // Helper function to format price
  const formatPrice = (price) => (price / 100).toFixed(2);

  console.log(product)

  return (
    <div className="product-content">
      <h1>{product.title}</h1>
      <p className='product-description padding-20-top'>{product.description}</p>
      <div className="product-info padding-20-top">
        {(product.category === "Sēņu micēlija bloks" || product.category === "Sēņu audzēšanas komplekts") && 
          <>
            <p><span className="bold">Augšanas ilgums: </span>{product.grow_time_min} - {product.grow_time_max} nedēļas </p>
            <p><span className="bold">Augšanas temperatūra: </span>{product.grow_temp_min} - {product.grow_temp_max} °C</p>
            <p><span className="bold">Uzglabāšanas termiņš: </span>{product.valid_for} mēneši</p>
          </>
        }
        {(selectedVariant && product.variant_type) && <p><span className="bold">{product.variant_type}: </span>{selectedVariant.variant_name}</p>}
      </div>

      {product.variants.length > 1 &&  
      <div className="flex column gap-10">
        <div className="variant-select flex padding-10-top">
          <select
            defaultValue={selectedVariant ? selectedVariant._id : "def"}
            className="basic-input"
            name="variant-select"
            id="variant-select"
            onChange={handleVariantChange}
          >
            <option value="def" disabled>Izvēlies izmēru</option>
            {product.variants.map(variant => (
              <option 
                key={variant._id} 
                value={variant._id} 
                disabled={variant.countInStock === 0}
                selected={selectedVariant?._id === variant._id}
              >
                {variant.variant_name} {variant.countInStock === 0 ? "(Nav pieejams)" : ""}
              </option>
            ))}
          </select>
          
        </div>
        {product.variants.every(variant => variant.countInStock === 0) && (
            <div className="out-of-stock-message">Nav noliktavā!</div>
          )}  
      </div>
      }

      {selectedVariant && 
      <div>
        {(selectedVariant.countInStock < 6 && selectedVariant.countInStock > 1) && <p className="low-stock padding-20-top">Pēdējās {selectedVariant.countInStock} preces!</p>}
        {selectedVariant.countInStock === 1 && <p className="low-stock padding-20-top">Pēdējā prece noliktavā!</p>}
        <p className={`product-price padding-20-top padding-10-bottom`}>
          <span className={`${(selectedVariant.discount_price && selectedVariant.discount_price > 0) ? 'discounted' : null}`}>€{formatPrice(selectedVariant.price)}</span>
          {(selectedVariant.discount_price && selectedVariant.discount_price > 0) ? <span>{selectedVariant.discount_price && `€${formatPrice(selectedVariant.discount_price)}`}</span> : null}
        </p>
        {selectedVariant.countInStock < 1 ? <p className="out-of-stock padding-20-top">Nav noliktavā</p> : <ButtonPrimary 
          text="Pievienot grozam" 
          onClick={cartHandler} 
          disabled={selectedVariant.countInStock < 1} 
          className={`add-to-cart-button ${selectedVariant.countInStock < 1 ? 'out-of-stock' : ''}`}
        />}
        
      </div>}
    </div>
  );
}

export default ProductContent;


// import { useState, useEffect } from 'react';
// import ButtonPrimary from "./style-components/ButtonPrimary";
// import { toast } from 'react-toastify';

// function ProductContent({ product, handleAddToCart }) {
//   // Initialize selectedVariant if there's only one variant
//   const [selectedVariant, setSelectedVariant] = useState(product.variants.length === 1 ? product.variants[0] : null);

//   const handleVariantChange = (event) => {
//     const variantId = event.target.value;
//     const variant = product.variants.find(v => v._id === variantId);
//     setSelectedVariant(variant);
//   };

//   const cartHandler = () => {
//     if (!selectedVariant) {
//       toast.error('Lūdzu izvēlies izmēru!');
//     } else {
//       const product_object = { ...product, variant: selectedVariant };
//       handleAddToCart(product_object);
//     }
//   }

//   return (
//     <div className="product-content">
//       <h1>{product.title}</h1>
//       <p className='product-description padding-20-top'>{product.description}</p>
//       <div className="product-info padding-20-top">
//         <p><span className="bold">Augšanas ilgums: </span>{product.grow_time_min} - {product.grow_time_max} nedēļas </p>
//         <p><span className="bold">Augšanas temperatūra: </span>{product.grow_temp_min} - {product.grow_temp_max} °C</p>
//         <p><span className="bold">Uzglabāšanas termiņš: </span>{product.valid_for} mēneši</p>
//       </div>
//       {product.variants.length > 0 &&  
//       <div className="flex column gap-10">
//         <div className="variant-select flex padding-10-top">
//           <select
//             defaultValue={selectedVariant ? selectedVariant._id : "def"}
//             className="basic-input"
//             name="variant-select"
//             id="variant-select"
//             onChange={handleVariantChange}
//           >
//             <option value="def" disabled>Izvēlies izmēru</option>
//             {product.variants.map(variant => (
//               <option 
//                 key={variant._id} 
//                 value={variant._id} 
//                 disabled={variant.countInStock === 0}
//                 selected={selectedVariant?._id === variant._id}
//               >
//                 {variant.size} {variant.countInStock === 0 ? "(Nav pieejams)" : ""}
//               </option>
//             ))}
//           </select>
          
//         </div>
//         {product.variants.every(variant => variant.countInStock === 0) && (
//             <div className="out-of-stock-message">Nav noliktavā!</div>
//           )}  
//       </div>
        
//       }

//       {selectedVariant && 
//       <div>
//         {product.variants.length > 1 && <p className="padding-20-top">Izvēlētais izmērs: {selectedVariant.size}</p>}
//         {(selectedVariant.countInStock < 6 && selectedVariant.countInStock > 1) && <p className="low-stock padding-20-top">Pēdējās {selectedVariant.countInStock} preces!</p>}
//         {selectedVariant.countInStock === 1 && <p className="low-stock padding-20-top">Pēdējā prece noliktavā!</p>}
//         <p className={`product-price padding-20-top padding-10-bottom`}>
//           <span className={`${selectedVariant.discount_price ? 'discounted' : null}`}>€{selectedVariant.price}</span>
//           <span>{selectedVariant.discount_price && `€${selectedVariant.discount_price}`}</span>
//         </p>
//         <ButtonPrimary text="Pievienot grozam" onClick={cartHandler} />
//       </div>}
//     </div>
//   );
// }

// export default ProductContent;