// Logout.js
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../features/auth/authSlice';
import { useNavigate } from 'react-router-dom';

function Logout() {
  const dispatch = useDispatch();
    const navigate = useNavigate();

  useEffect(() => {
    // Dispatch the logout action when the component mounts
    dispatch(logout());
    navigate('/');
    window.location.reload();

  }, [dispatch]);

  // You can optionally add a message or UI element to indicate that the user is logged out

  return (
    <div>
      {/* Add any logout message or UI element here */}
    </div>
  );
}

export default Logout;