import { useState, useEffect } from 'react';
import logo from '../../img/ns_logo.png';
import logo_light from '../../img/logo_light.png';
import CartCounter from '../style-components/CartCounter';
import MenuItem from '../style-components/MenuItem';
import { Cross as Hamburger } from 'hamburger-react';
import { useNavigate, useLocation } from 'react-router-dom';
import ClientMobile from './ClientMobile';
import { animateScroll as scroll, scroller } from 'react-scroll';

function HeaderClient({ mobile, light }) {
  const [isHome, setIsHome] = useState(false);
  const [isSubmenuOpen, setSubmenuOpen] = useState(false);
  const [scrollToVeikals, setScrollToVeikals] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const toggleSubmenu = () => {
    setSubmenuOpen(!isSubmenuOpen);
  };

  useEffect(() => {
    setIsHome(location.pathname === '/');
  }, [location]);

  useEffect(() => {
    setSubmenuOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    if (scrollToVeikals && isHome) {
      scroller.scrollTo('veikals', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: -100, // Scrolls to 100px above the element
      });
      setScrollToVeikals(false);
    }
  }, [scrollToVeikals, isHome]);

  const handleVeikalsClick = (e) => {
    e.preventDefault();
    if (location.pathname !== '/') {
      setScrollToVeikals(true);
      navigate('/');
    } else {
      scroller.scrollTo('veikals', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: -100, // Scrolls to 100px above the element
      });
    }
  };

  const handleLogoClick = () => {
    if (isHome) {
      scroll.scrollToTop({
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    } else {
      navigate('/');
    }
  };

  useEffect(() => {
    if (location.hash === '#veikals') {
      scroller.scrollTo('veikals', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: -100, // Scrolls to 100px above the element
      });
    }
  }, [location]);

  return (
    <div className={`header-container ${isHome ? 'light' : ''}`}>
      {mobile ? (
        <ClientMobile isHome={isHome} />
      ) : (
        <>
          <div className="top-bar">
            <div className="search"></div>
            <div className="logo" onClick={handleLogoClick}>
              {isHome ? (
                <img src={logo_light} alt="NoraSpora" />
              ) : (
                <img src={logo} alt="NoraSpora" />
              )}
            </div>
            <div className={`header-cart ${isHome ? 'light' : ''}`}>
              <CartCounter />
            </div>
          </div>
          <div className="menu padding-10-top padding-10-bottom">
            <div className="menu-item-with-submenu">
              <MenuItem text="Veikals" onClick={handleVeikalsClick} />
            </div>
            <MenuItem text="Instrukcija" link="/instrukcija" />
            <MenuItem text="Jautājumi" link="/jautajumi" />
            <MenuItem text="Par mums" link="/about" />
            <MenuItem text="Kontakti" link="/contacts" />
          </div>
        </>
      )}
    </div>
  );
}

export default HeaderClient;
