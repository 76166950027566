import { Helmet } from 'react-helmet'

function LietosanasNoteikumi() {
  return (
    <div className="noteikumi">
      <Helmet>
        <title>Nora Spora - Lietošanas noteikumi</title>
        <meta name="description" content="Izpētiet mūsu lietošanas noteikumus.." />
        <meta name="keywords" content="privātuma politika, lietosanas noteikumi, datu aizsardzība, personas dati, NORA SPORA" />
        <meta name="robots" content="index, follow" />
      </Helmet>
        <h2 className="light">Lietošanas noteikumi</h2>
        <ol>
            <li>Vispārīgie noteikumi
                <ol>
                    <li>
                    Šie Noteikumi stājas spēkā, kad Pircējs tos ir apstiprinājis. Pircējs ir izveidojis iepirkumu grozu un pēdējā iepirkšanās procesa solī atzīmējis ķeksīti “Esmu izlasījis un piekrītu lapas noteikumiem” un nospiedis “Pasūtīt” pogu.
                    </li>
                    <li>
                    Pircējs apņemas pasūtīt un pieņemt Preces un samaksāt par tām Pasūtījuma cenu Līgumā noteiktā kārtībā. Preču piegāde tiek veikta tikai interneta veikalā www.noraspora.lv norādītajā teritorijā.
                    </li>
                    <li>
                    Pircējs nav tiesīgs iegādāties interneta veikalā www.noraspora.lv pieejamās Preces, ja nav iepazinies ar Pirkšanas noteikumiem vai nepiekrīt tiem.
                    </li>
                    <li>
                    Preču cenas interneta veikalā www.noraspora.lv ir norādītas eiro, atbilstoši spēkā esošajos normatīvajos aktos noteiktai kārtībai. Cenā nav iekļautas Preces piegādes izmaksas. Preču piegādes cena tiek norādīta atsevišķi, veicot katru Pasūtījumu.
                    </li>
                </ol>
            </li>
            <li>
                Personas datu aizsardzība
                <ol>
                    <li>
                    noraspora.lv rūpējas par savu Pircēju privātumu un personas datu aizsardzību, ievēro Pircēju tiesības uz personas datu apstrādes likumību saskaņā ar Eiropas Parlamenta un Padomes 2016. gada 27. aprīļa Regulu 2016/679 par fizisku personu aizsardzību attiecībā uz personas datu apstrādi un šādu datu brīvu apriti un citiem Latvijas Republikā spēkā esošajiem normatīvajiem aktiem, atbildīgo institūciju izdotajiem saistošajiem norādījumiem un noteikumiem privātuma un datu apstrādes jomā
                    </li>
                    <li>
                    Pārdevējs apņemas apstrādāt šajā līgumā minētos personas datus, saglabāt tos tikai atbilstoši paredzētajam mērķim un tam nepieciešamajā apjomā, tajā skaitā servisa uzlabošanai, reklāmas materiālu izsūtīšanai. Pārdevējs ir tiesīgs nodot Pircēja personas datus sadarbības partneriem un datu apstrādes kompānijām, kas strādā Pārdevēja labā. Apkopotā informācija var tikt izmantota stratēģiskiem un mārketinga mērķiem.
                    </li>
                </ol>
            </li>
            <li>
                Pirkšanas – pārdošanas līguma noslēgšana
                <ol>
                    <li>
                    Līgums starp noraspora.lv un Pircēju stājas spēkā brīdī, kad Pircējs Mājaslapā ir iepazinies ar izvēlētās preces aprakstu, izvēlējies Preces, noformējis Pirkuma grozu, tādējādi apliecinot, ka preces atbilst Pircēja izvirzītajām prasībām, ir piekritis Noteikumiem un Privātuma politikai, ir veicis Pasūtījuma apmaksu un ir saņēmis Pasūtījumu.
                    </li>
                    <li>
                    Interneta veikalam nav pienākums atsevišķi paziņot Pircējam par savu piekrišanu līguma slēgšanai. Tiek uzskatīts, ka interneta veikals apstiprina pasūtījumu no brīža, kad tas ir apmaksāts.
                    </li>
                    <li>
                    Katrs starp Pircēju un Pārdevēju noslēgtais līgums (pasūtījums) tiek reģistrēts un uzglabāts interneta veikala datu bāzē.
                    </li>
                </ol>
            </li>
            <li>
                Pircēja tiesības un pienākumi
                <ol>
                    <li>
                    Pircējam – fiziskai personai ir atteikuma tiesības. Pircējs var izmantot atteikuma tiesības attiecībā uz vietnē www.noraspora.lv nopirktām precēm 7 dienu laikā no brīža, kad tās nonākušas Pircēja valdījumā. Epasts ar informāciju par atgriežamo preci un pasūtījuma numuru jāraksta uz info@noraspora.lv.
                    </li>
                    <li>
                    Piekļūstot Mājaslapai vai lietojot to, Jums ir jāievēro šeit izklāstītie Noteikumi un nosacījumi, un Mājaslapā publicētie īpašie brīdinājumi vai norādījumi attiecībā uz piekļuvi vai lietošanu. Jums vienmēr jārīkojas labticīgi un saskaņā ar likumu.
                    </li>
                    <li>
                    Pircējs var izmantot atgriešanas tiesības tikai tad, ja prece nav bojāta vai tās izskats nav būtiski mainījies. Pircējs ir atbildīgs par to, lai Precei nesamazinātos tās vērtība, kvalitāte un drošums. Pretējā gadījumā Pārdevējam ir tiesības atteikt Pircējam atteikuma tiesību izmantošanu attiecībā uz piegādāto Preci.
                    </li>
                </ol>
            </li>
            <li>
                Pircēja pienākumi
                <ol>
                    <li>
                    Pircējs apņemas reģistrācijas formā sniegt tikai pareizu un pilnīgu informāciju. Mainoties reģistrācijas formā norādītajai informācijai, Pircējam ir pienākums nekavējoties to atjaunot.
                    </li>
                    <li>
                    Pircējs apņemas taisnīgi un pareizi izmantot interneta veikalu, nekaitēt tā darbam vai stabilai darbībai Ja Pircējs nepilda šo pienākumu, Pārdevējam ir tiesības bez iepriekšēja brīdinājuma ierobežot, apturēt (pārtraukt) Pircēja piekļuvi interneta veikalam, un Pārdevējs nav atbildīgs par jebkādiem ar to saistītiem Pircēja zaudējumiem.
                    </li>
                    <li>
                    Pircējam ir jāsamaksā par pasūtītajām Precēm un jāsaņem tās šajos Noteikumos noteiktajā kārtībā.
                    </li>
                    <li>
                    Neskatoties uz citos Noteikumu punktos paredzētajām saistībām, Pircējs apņemas 48 stundu laikā pēc Preču saņemšanas pārbaudīt Preces un pārliecināties, ka saņemtās Preces ir tās, kuras Pircējs pasūtījis.
                    </li>
                    <li>
                    Pircējs apņemas nenodot trešajām personām savus pieteikšanās datus. Ja Pircējs pazaudē pieslēgšanās datus, viņam par to nekavējoties jāinformē Pārdevējs pa sadaļā “Kontakti” norādītajiem kontaktiem. Pārdevējs nav atbildīgs par trešo personu rīcību, izmantojot Pircēja pieslēgšanās datus.
                    </li>
                    <li>
                    Ja Pircējs preču piegādes laikā bez svarīga iemesla atsakās pieņemt preces, Pircējam ir jāsedz preces piegādes un atgriešanas izmaksas, kas tiek ieturētas no Pircēja samaksātajiem naudas līdzekļiem par pasūtītajām precēm.
                    </li>
                </ol>
            </li>

            <li>
          Pārdevēja tiesības
          <ol>
            <li>
              Pārdevējam ir tiesības Pakalpojuma sniegšanā piesaistīt savu personālu vai izmantot apakšuzņēmējus.
            </li>
            <li>
              Pārdevējam ir tiesības bez iepriekšēja brīdinājuma ierobežot, apturēt (pārtraukt) Pircēja piekļuvi interneta veikalam, un Pārdevējs nav atbildīgs par jebkādiem ar to saistītiem Pircēja zaudējumiem.
            </li>
            <li>
              Pārdevējam ir tiesības anulēt pasūtījumu, iepriekš nebrīdinot Pircēju, ja Pircējs 3 (trīs) darba dienu laikā nesamaksā par preci ar veikala mājaslapā norādītajiem norēķinu veidiem.
            </li>
            <li>
              Ja rodas šaubas par pasūtījumā sniegto informāciju, Pārdevējs sazinās ar Pircēju pa pasūtījumā norādītajiem kontaktiem. Šajā gadījumā preču piegāde notiek tad, kad Pircējs ir precizējis informāciju.
            </li>
            <li>
              Neparedzētu apstākļu gadījumā Pārdevējs var uz laiku vai pavisam pārtraukt interneta veikala darbību, iepriekš nebrīdinot Pircēju. Šajā gadījumā Pārdevējs apņemas pildīt ar Pircēju noslēgto pirkuma-pārdošanas līgumu vai atdot Pircēja samaksātos naudas līdzekļus.
            </li>
          </ol>
        </li>
        <li>
          Pārdevēja pienākumi
          <ol>
            <li>
              Pārdevējs apņemas sniegt Pakalpojumu atbilstoši spēkā esošo normatīvo aktu prasībām, kvalitatīvi, ievērojot Pakalpojuma sniedzēja profesijai noteiktos standartus, kritērijus un ētikas normas.
            </li>
            <li>
              Pārdevējs apņemas izpildīt Līgumu un piegādāt Preces Pircējam saskaņā ar Līguma noteikumiem un tādā komplektācijā, kāda paredzēta Pasūtījumos.
            </li>
            <li>
              Ja Pārdevējs nevar izpildīt kādu no Noteikumos ietvertajiem izpildes nosacījumiem (termiņš, apjoms, kvalitāte u.c.), tas savlaicīgi informē Pircēju, sazinoties pa norādīto telefona numuru vai e-pastu, norādot iemeslus un piedāvājot izdarīt grozījumus Pasūtījuma vai Noteikumu nosacījumos. Risinājums tiek rasts atbilstoši situācijai, un abām pusēm vienojoties.
            </li>
          </ol>
        </li>
        <li>
          Preču cenas un apmaksas kārtība
          <ol>
            <li>
              Pārdevējs patur tiesības mainīt preču cenas bez iepriekšēja brīdinājuma.
            </li>
            <li>
              Pircējs norēķinās par precēm, izmantojot e-veikala piedāvātos apmaksas veidus, kas aprakstīti sadaļā “Maksājuma veidi”.
            </li>
          </ol>
        </li>
        <li>
          Preču piegāde
          <ol>
            <li>
              Mēs nodrošinām visu Internetveikalā pieejamo preču pasūtījumu pieņemšanu, apstrādi un izpildi, savukārt preču piegādes nodrošina mūsu pilnvaroti sadarbības partneri – preču piegādes pakalpojumu sniedzēji.
            </li>
            <li>
              Pasūtot preces, pircējs apņemas norādīt precīzu preču piegādes adresi.
            </li>
            <li>
              Pircējs apņemas preces saņemt pats. Gadījumā, ja Pircējs pats nevar pieņemt preces un preces tiek piegādātas uz Pircēja norādīto adresi, Pircējam nav tiesību izvirzīt Pārdevējam pretenzijas par preces piegādi nepareizai personai.
            </li>
            <li>
              Preču piegādes brīdī Pircējam kopā ar pilnvaroto pārstāvi jāpārbauda sūtījuma stāvoklis. Pircējam parakstot rēķinu (pavadzīmi) vai citu sūtījuma nodošanas-pieņemšanas dokumentu, tiek uzskatīts, ka sūtījums ir piegādāts atbilstošā stāvoklī.
            </li>
            <li>
              Piegādes maksa un sīkāka informācija saistībā ar preču piegādi ir sniegta interneta veikala sadaļā “Piegāde”.
            </li>
            <li>
              Ja pasūtītās preces nav iespējams piegādāt vienā sūtījumā, Pārdevējam ir tiesības preces piegādāt vairākos sūtījumos.
            </li>
            <li>
              Pārdevējam ir tiesības, iepriekš informējot Pircēju, mainīt Preču piegādes laiku.
            </li>
            <li>
              Par preču piegādi jāmaksā piegādes maksa. Piegādes maksa ir atkarīga no izvēlētā piegādes veida.
            </li>
          </ol>
        </li>
        <li>
          Produktu kvalitātes garantija un derīguma termiņš
          <ol>
            <li>
              Visu pārdodamo Preču īpašības ir norādītas katras Preces aprakstā. Preču ražotāji un (vai) importētāji ir atbildīgi par informāciju par Preču īpašībām (tās pareizību), un Pārdevējs ir atbildīgs tikai par šīs informācijas pareizu sniegšanu (pārsūtīšanu) patērētājiem, ja vien likumā nav noteikts citādi. Gadījumos, kad likumdošana nosaka noteiktu derīguma termiņu konkrētām Precēm, Pārdevējs apņemas pārdot šādas Preces Pircējam tādā veidā, kas sniedz viņam reālu iespēju izmantot šādas Preces līdz derīguma termiņa beigām.
            </li>
            <li>
              Pārdevējs nav atbildīgs par to, ka interneta veikalā esošās preces pēc savas krāsas, formas vai citiem parametriem var neatbilst faktiskajam preces izmēram, formai un krāsai Pircēja izmantotā monitora īpašību dēļ.
            </li>
            <li>
              Gadījumā, ja Pārdevējs atsevišķiem preču veidiem nesniedz kvalitātes garantiju, tiek piemērota attiecīgajos tiesību aktos paredzētā garantija.
            </li>
          </ol>
        </li>

        <li>
          Preču atgriešana un maiņa
          <ol>
            <li>
            Pircējs var izmantot atgriešanas tiesības tikai tad, ja prece nav bojāta vai tās izskats nav būtiski mainījies. Pircējs ir atbildīgs par to, lai Precei nesamazinātos tās vērtība, kvalitāte un drošums. Pretējā gadījumā Pārdevējam ir tiesības atteikt Pircējam atteikuma tiesību izmantošanu attiecībā uz piegādāto Preci.
            </li>
            <li>
            Preces ir jāatgriež oriģinālajā Preču un sūtījuma iepakojumā kopā ar Preču dokumentiem. Pirms preču atgriešanas Pircējam ir jāsazinās ar Pārdevēju, rakstot e-pastu uz info@noraspora.lv.
            </li>
            <li>
            Pārdevējam ir tiesības nepieņemt Preci un atteikt Pircējam izmantot atteikuma tiesības, kā arī neatmaksāt Pircēja par Preci samaksāto naudu, ja Preces atgriešanas brīdī ir konstatējis bojājumus Precei (noņemtas un / vai sabojātas etiķetes, noplēstas aizsardzības plēves u. c.) un / vai ir konstatētas Preces lietošanas pazīmes.
            </li>
          </ol>
        </li>

        <li>
          Informācijas apmaiņa
          <ol>
            <li>
            Pārdevējs visus paziņojumus nosūta uz Pircēja reģistrācijas anketā norādīto e-pasta adresi.
            </li>
            <li>
            Pircējs visus paziņojumus un jautājumus nosūta uz Pārdevēja interneta veikala sadaļā “Kontakti” norādītajiem kontaktiem.
            </li>
          </ol>
        </li>

        <li>
        Nobeiguma noteikumi
        <ol>
          <li>
          Uzņēmums var atjaunināt noteikumus.
          </li>
          <li>
          Visi strīdi, kas rodas no šo noteikumu piemērošanas, tiek risināti sarunu ceļā. Ja vienošanās netiek panākta, strīdi tiek risināti saskaņā ar Latvijas Republikas likumiem.
          </li>
        </ol>
        </li>

        </ol>
    </div>
  )
}

export default LietosanasNoteikumi