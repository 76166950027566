import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { getTexts, addText } from '../../features/texts/textSlice';
import ButtonPrimary from '../../components/style-components/ButtonPrimary';

function AdminTexts() {

  const [tag, setTag] = useState('')
  const [value, setValue] = useState('')
  const [selectedText, setSelectedText] = useState(null)

  const { texts, isLoading, isError, isSuccess, message } = useSelector((state) => state.texts);

  const dispatch = useDispatch()
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getTexts())
  }, [dispatch])

  const { user } = useSelector((state) => state.auth);

    useEffect(() => {
      if (!user) {
        navigate('/');
      }
    }, [user, navigate]);

  const handleAddText = () => {
    if (selectedText) {
      // Update existing text
      dispatch(addText({ ...selectedText, tag, value }))
    } else {
      // Add new text
      dispatch(addText({ tag, value }))
    }
    setSelectedText(null)
    setTag('')
    setValue('')
  }

  const handleTextClick = (text) => {
    setSelectedText(text)
    setTag(text.tag)
    setValue(text.value)
  }

  return (
    <div className=''>
        <h2 className="light">Mājaslapas iestatījumi</h2>
        <h5 className="light padding-20-top padding-10-bottom">Rediģēt instrukcijas tekstus:</h5>
        {/* <input type="text" placeholder="Tag" value={tag} onChange={(e) => setTag(e.target.value)} />
        <ReactQuill placeholder="Teksts" className="my-quill" value={value} onChange={setValue} />
        <button onClick={handleAddText}>{selectedText ? 'Atjaunināt' : 'Pievienot'}</button> */}

        {texts && texts.map((text) => (
          <div key={text._id}>
            <div 
              className={`admin-instrukcija-teksts ${selectedText && selectedText._id === text._id ? 'active' : ''}`} 
              onClick={() => handleTextClick(text)}
            >
              <p>
                <strong>{text.tag}</strong> - {text.value}
              </p>
            </div>

            {selectedText && selectedText._id === text._id && 
              <>
                <ReactQuill placeholder="Teksts" className="my-quill" value={value} onChange={setValue} />
                <div className="padding-10-bottom"></div>
                <ButtonPrimary text="Atjaunināt" onClick={handleAddText} />
                <div className="padding-10-bottom"></div>
              </>
            }
          </div>
        ))}
    </div>  
  )
}

export default AdminTexts



// import { useState, useEffect } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
// import { FaEdit } from "react-icons/fa";
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
// import { getTexts, addText } from '../../features/texts/textSlice';

// function AdminTexts() {

//   const [tag, setTag] = useState('')
//   const [value, setValue] = useState('')

//   const { texts, isLoading, isError, isSuccess, message } = useSelector((state) => state.texts);

//   const dispatch = useDispatch()

//   useEffect(() => {
//     dispatch(getTexts())
//   }, [dispatch])

//   const handleAddText = () => {
//     dispatch(addText({ tag, value }))
//   }

//   return (
//     <div className=''>
//         <h2 className="light">Mājaslapas iestatījumi</h2>
//         <h5 className="light padding-20-top padding-10-bottom">Rediģēt instrukcijas tekstus:</h5>
//         {/* <input type="text" placeholder="Tag" value={tag} onChange={(e) => setTag(e.target.value)} />
//         <ReactQuill placeholder="Teksts" className="my-quill" value={value} onChange={setValue} />
//         <button onClick={handleAddText}>Pievienot</button> */}

//         {texts && texts.map((text) => (
//           <div key={text._id} className="admin-instrukcija-teksts">
//             <p>
//               <strong>{text.tag}</strong> - {text.value}
//             </p>
//           </div>
//         ))}
//     </div>  
//   )
// }

// export default AdminTexts