import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getQuestions, deleteQuestion, reset } from '../../features/questions/questionSlice'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'
import ButtonPrimary from '../../components/style-components/ButtonPrimary'

function AdminQuestions() {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { questions, isLoading, isError, message } = useSelector((state) => state.question)

    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
      if (!user) {
        navigate('/');
      }
    }, [user, navigate]);


    useEffect(() => {
        if (isError) {
            toast.error(message)
        }

        return () => {
            dispatch(reset());
        };
    }, [questions, isLoading, isError, message, dispatch]);

    useEffect(() => {
        dispatch(getQuestions())
    }, [])

    const handleDelete = (id) => {
        dispatch(deleteQuestion(id))
    }

  return (
    <div style={{width: "100%"}} className='padding-20-bottom'>
        <div className="add-button-container padding-20-bottom">
            <ButtonPrimary onClick={() => navigate('/edit-questions/add')} text="Pievienot jautājumu"/>
        </div>
      <h2 className='light'>Jautājumu Saraksts:</h2>
      
      <table className='admin-edit-products-table padding-20-top'>
        <thead>
          <tr>
            <th style={{textAlign: "left"}}>Jautājums</th>
            <th style={{width: "10%"}}>Dzēst</th>
          </tr>
        </thead>
        <tbody>
          {questions.map((question, index) => (
              <tr key={index} >
                  <td className='title' onClick={() => navigate(`/edit-questions/${question._id}`)}>{question.question}</td>
                  <td className='remove'><FaTimes className="cart-remove-button" size={20} onClick={() => handleDelete(question._id)}/></td>
              </tr>
            
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default AdminQuestions