import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getProductsAdmin, getTrashedProducts, reset } from '../../features/products/productSlice';
import { toast } from 'react-toastify';
import ProductList from '../../components/admin/ProductList';
import Spinner from '../../components/Spinner';

function AdminProducts({ trash = false }) {
    const { products, isLoading, isSuccess, isError, message } = useSelector((state) => state.product);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
      if (!user) {
        navigate('/');
      }
    }, [user, navigate]);

    useEffect(() => {
        if (trash) {
            dispatch(getTrashedProducts());
        } else {
            dispatch(getProductsAdmin());
        }
    }, [trash, dispatch]);

    useEffect(() => {
        if (isError) {
            toast.error(message);
        }

        return () => {
            dispatch(reset());
        };
    }, [isError, message, dispatch]);

    if (isLoading) return <Spinner />;

    return (
        <div className='admin padding-20-bottom'>
            <ProductList products={products} trash={trash} />
        </div>
    );
}

export default AdminProducts;