import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { login, reset } from '../features/auth/authSlice'
import Spinner from '../components/Spinner'

function Login() {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  })

  const { username, password } = formData

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  )

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }
  
    if (user) {
      navigate('/')
    }
  
    // Return a cleanup function that runs when the component unmounts
    return () => {
      dispatch(reset())
    }
  }, [user, isError, isSuccess, message, navigate, dispatch])

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const userData = {
      username,
      password,
    }

    dispatch(login(userData))
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <div className="login-page">
      <section className="login-title">
          <h2 className='light'>Nora Spora Login</h2>
          <p>Lūdzu ievadi lietotājvārdu un paroli, lai piekļūtu Nora Spora admin panelim.</p>
      </section>
        
      <form onSubmit={onSubmit} className='login-form'>
          <input
          type='text'
          className='form-control'
          id='username'
          name='username'
          value={username}
          placeholder='Lietotājvārds'
          onChange={onChange}
          />
          <input
          type='password'
          className='form-control'
          id='password'
          name='password'
          value={password}
          placeholder='Parole'
          onChange={onChange}
          />

          <button type='submit' className='login btn btn-block'>Pieslēgties</button>
      </form>
    </div>
    
  )
}

export default Login