import { useEffect, useState, useRef } from 'react';

function SelectDropdown({ setSelectedValue, values, placeholder, value }) {
  const dropdownRef = useRef(null);

  const [searchTerm, setSearchTerm] = useState(value || '');
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  useEffect(() => {
    setSearchTerm(value || '');
  }, [value]);

  const handleValueChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  // Filter values based on search term
  const filteredValues = values?.filter((val) =>
    val.toLowerCase().includes(searchTerm)
  );

  const handleFocus = () => {
    setIsDropdownVisible(true);
    setSearchTerm('');
  };

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="flex column select-dropdown padding-20-bottom">
      <input
        type="text"
        placeholder={placeholder || 'Meklēt...'}
        value={searchTerm}
        onChange={handleValueChange}
        onFocus={handleFocus}
        className="padding-10"
      />
      {isDropdownVisible && (
        <div className="dropdown-menu" ref={dropdownRef}>
          {filteredValues?.map((val, index) => (
            <div
              key={index}
              onMouseDown={() => {
                setSelectedValue(val);
                setIsDropdownVisible(false); // Hide dropdown after selecting
                setSearchTerm(val);
              }}
              className="dropdown-item padding-10"
            >
              {val}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SelectDropdown;
