import axios from 'axios'

const API_URL = '/shipping/'

// Get Omniva locations
const getOmnivaLocations = async (order) => {
    const response = await axios.get(API_URL + 'locations/omniva')
    return response.data
}

const getSmartpostLocations = async (order) => {
    const response = await axios.get(API_URL + 'locations/smartpost')
    return response.data
}

const shippingService = {
    getOmnivaLocations,
    getSmartpostLocations
}
  
export default shippingService
