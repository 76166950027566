import { Helmet } from 'react-helmet'

function PiegadesNoteikumi() {
  return (
    <div className="noteikumi piegade" style={{minHeight: "calc(-60px + 100vh - var(--header-height))"}}>
      <Helmet>
        <title>Nora Spora - Piegādes noteikumi</title>
        <meta name="description" content="Izpētiet mūsu piegādes noteikumus, lai uzzinātu kā mēs piegādāsim Jūsu pasūtījumu." />
        <meta name="keywords" content="privātuma politika, datu aizsardzība, personas dati, NORA SPORA" />
        <meta name="robots" content="index, follow" />
      </Helmet>
        <h2 className="light">Piegādes nosacījumi</h2>
        <ul>
            <li>Pasūtījumus piegādājam 2-5 darba dienu laikā (protams, cenšamies piegādāt pēc iespējas ātrāk).</li>
            <li>Piegāde notiek Latvijas Republikas teritorijas robežās (uz citām teritorijām pēc atsevišķa vienošanās).</li>
            <li>Standarta piegāde pasūtītajām precēm tiek veikta ar Omniva pakomātu pakalpojumiem uz Jūsu norādīto adresi</li>
            <li>Bezmaksas piegāde sūtījumiem virs 40 eiro</li>
            <li>2,99 €  – standarta piegāde uz Omniva pakomātiem sūtījumiem līdz 40 €.</li>
            <li style={{fontWeight: "500"}}>Papildu informācija par piegādi:
                <ul style={{listStyle:"disc", paddingLeft:"20px", fontWeight:"400"}}>
                    <li>Uzmanību: Saņemot sūtījumu, lūdzu, pārbaudiet sūtījuma iepakojuma stāvokli. Ja ir radušies bojājumi vai ir aizdomas par bojājumiem, 48h laikā kopš preces saņemšanas, raksties e-pastu ar šo iebildumu un bojājumu specifikāciju uz info@noraspora.lv.</li>
                    <li>Ja sūtījumā nav kādas preces vai šķiet, ka iepakojums ir bojāts, lūdzu, informējiet mūs pa e-pastu: info@noraspora.lv.</li>
                </ul>
            </li>
        </ul>
    </div>
  )
}

export default PiegadesNoteikumi
