
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import orderService from './orderService'
import { toast } from 'react-toastify'

const initialState = {
  orders: [],
  labels: [],
  order_doc: null,
  label: null,
  order: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Get all orders
export const getOrders = createAsyncThunk('orders/getAll', async (_, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await orderService.getOrders(token)
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

// Get unpaid orders
export const getUnpaidOrders = createAsyncThunk('orders/getUnpaid', async (_, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await orderService.getUnpaidOrders(token)
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

// Get order by id
export const getOrder = createAsyncThunk('orders/getOne', async (orderId, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await orderService.getOrder(token, orderId)
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

// Get order public
export const getOrderPublic = createAsyncThunk('orders/getOnePublic', async (orderId, thunkAPI) => {
    try {
        return await orderService.getOrderPublic(orderId)
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
});

// Initiate Stripe payment session
export const initiateStripe = createAsyncThunk('orders/initiateStripe', async (orderData, thunkAPI) => {
    try {
        return await orderService.initiateStripe(orderData);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// Toggle status
export const toggleStatus = createAsyncThunk('orders/toggleStatus', async (orderData, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token;
        return await orderService.toggleStatus(token, orderData);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// Toggle status
export const toggleStatusMultiple = createAsyncThunk('orders/toggleStatus/multiple', async (orderData, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token;
        return await orderService.toggleStatus(token, orderData);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// Contact us
export const contactUs = createAsyncThunk('orders/contactUs', async (contactData, thunkAPI) => {
    try {
        return await orderService.contactUs(contactData);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// Resend email
export const resendEmail = createAsyncThunk('orders/resendEmail', async (orderId, thunkAPI) => {
    try {
        return await orderService.resendEmail(orderId);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// Fetch shipping labels for selected orders
export const fetchShippingLabels = createAsyncThunk('orders/fetchShippingLabels', async (ids, thunkAPI) => {
    try {
        // const token = thunkAPI.getState().auth.user.token;
        return await orderService.getShippingLabels(ids);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// Fetch shipping labels for selected orders
export const exportOrders = createAsyncThunk('orders/excel', async (ids, thunkAPI) => {
    try {
        // const token = thunkAPI.getState().auth.user.token;
        return await orderService.exportOrders(ids);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const createCustomInvoice = createAsyncThunk('orders/createCustomInvoice', async (data, thunkAPI) => {
    try {
        return await orderService.getCustomInvoice(data);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

const downloadExcel = (data, filename) => {
    // Create a Blob from the Excel data (received as a buffer from the backend)
    const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    // Create a link element
    const link = document.createElement('a');
    
    // Create a URL for the Blob and set it as the href attribute of the link
    link.href = window.URL.createObjectURL(blob);
    
    // Set the download attribute to the filename you want
    link.download = filename;

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up by removing the link from the DOM
    document.body.removeChild(link);
};


const downloadPdf = (label, fileName = 'pasutijumi.pdf') => {
    const data = label.shipping_label.data;
    console.log(data)

    // Convert byte array to Uint8Array
    const byteArray = new Uint8Array(data);

    // Create a Blob with PDF MIME type
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a link element and trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName); // Set the file name
    document.body.appendChild(link);
    link.click(); // Trigger the download
    link.parentNode.removeChild(link); // Clean up
    window.URL.revokeObjectURL(url); // Clean up the URL object
};

const downloadInvoice = (label, fileName = 'rekins.pdf') => {
    const data = label.invoiceBuffer.data;
    // Convert byte array to Uint8Array
    const byteArray = new Uint8Array(data);

    // Create a Blob with PDF MIME type
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a link element and trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName); // Set the file name
    document.body.appendChild(link);
    link.click(); // Trigger the download
    link.parentNode.removeChild(link); // Clean up
    window.URL.revokeObjectURL(url); // Clean up the URL object
};

export const orderSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
      reset: (state) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = false
        state.message = ''
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(getOrders.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getOrders.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.orders = action.payload
            state.order = null
        })
        .addCase(getOrders.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(getUnpaidOrders.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getUnpaidOrders.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.orders = action.payload
        })
        .addCase(getUnpaidOrders.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(getOrder.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getOrder.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.order = action.payload
        })
        .addCase(getOrder.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(initiateStripe.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(initiateStripe.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            window.location.href = action.payload.url;
        })
        .addCase(initiateStripe.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
            toast.error(action.payload);
        })
        .addCase(getOrderPublic.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(getOrderPublic.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.order = action.payload;
        })
        .addCase(getOrderPublic.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
        })
        .addCase(toggleStatus.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(toggleStatus.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.order.status = action.payload.status;
        })
        .addCase(toggleStatus.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
        })
        .addCase(toggleStatusMultiple.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(toggleStatusMultiple.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.orders = state.orders.map((order) => {
                if (action.payload.ids.includes(order._id)) {
                    order.status = action.payload.status;
                }
                return order;
            });
        })
        .addCase(toggleStatusMultiple.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
        })
        .addCase(contactUs.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(contactUs.fulfilled, (state) => {
            state.isLoading = false;
            state.isSuccess = true;
            toast.success('Paldies par jūsu ziņu! Mēs ar jums sazināsimies drīzumā.');
        })
        .addCase(contactUs.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
            toast.error('Kaut kas nogāja greizi. Lūdzu, mēģiniet vēlreiz.');
        })
        .addCase(resendEmail.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(resendEmail.fulfilled, (state) => {
            state.isLoading = false;
            state.isSuccess = true;
            toast.success('Jūs drīz saņemsiet apstiprinājuma e-pastu.');
        })
        .addCase(resendEmail.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
            toast.error('Jūsu pasūtījumu esam saņēmuši, bet nevarējām nosūtīt apstiprinājuma e-pastu. Lūdzu sazinieties ar mums.');
        })
        .addCase(fetchShippingLabels.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(fetchShippingLabels.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            for(const label of action.payload) {
                downloadPdf(label, `Ceļa karte ${label.firstName} ${label.lastName}.pdf`);
            }

        })
        .addCase(fetchShippingLabels.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
            toast.error(action.payload);
        })
        .addCase(exportOrders.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(exportOrders.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            downloadExcel(action.payload, 'orders.xlsx');
        })
        .addCase(exportOrders.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
            toast.error(action.payload);
        })
        .addCase(createCustomInvoice.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(createCustomInvoice.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            downloadInvoice(action.payload, 'invoice.pdf');
        })
        .addCase(createCustomInvoice.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
            toast.error(action.payload);
        })

        
    },
  })
  
  export const { reset } = orderSlice.actions
  export default orderSlice.reducer
  