
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import questionService from './questionService'

const initialState = {
  questions: [],
  question: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Get all questions
export const getQuestions = createAsyncThunk('questions/getAll', async (_, thunkAPI) => {
    try {
        return await questionService.getQuestions()
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

// Get question by ID
export const getQuestionById = createAsyncThunk('questions/getById', async (id
    , thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token;
        return await questionService.getQuestionById(token, id)
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
});

// Create a question
export const createQuestion = createAsyncThunk('questions/create', async (question, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token;
        return await questionService.createQuestion(token, question)
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
});

// Update a question
export const updateQuestion = createAsyncThunk('questions/update', async (question, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token;
        return await questionService.updateQuestion(token, question)
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
});

// Delete a question
export const deleteQuestion = createAsyncThunk('questions/delete', async (id, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token;
        return await questionService.deleteQuestion(token, id)
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
});


export const questionSlice = createSlice({
    name: 'questions',
    initialState,
    reducers: {
      reset: (state) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = false
        state.message = ''
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(getQuestions.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getQuestions.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.questions = action.payload
        })
        .addCase(getQuestions.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(getQuestionById.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getQuestionById.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.question = action.payload
        })
        .addCase(getQuestionById.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(createQuestion.pending, (state) => {
            state.isLoading = true
        })
        .addCase(createQuestion.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.questions.push(action.payload)
        })
        .addCase(createQuestion.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(updateQuestion.pending, (state) => {
            state.isLoading = true
        })
        .addCase(updateQuestion.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.question = state.questions.map((question) => question._id === action.payload._id ? action.payload : question)
        })
        .addCase(updateQuestion.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(deleteQuestion.pending, (state) => {
            state.isLoading = true
        })
        .addCase(deleteQuestion.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.questions = state.questions.filter((question) => question._id !== action.payload._id)
        })
        .addCase(deleteQuestion.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
    },
  })
  
  export const { reset } = questionSlice.actions
  export default questionSlice.reducer
  