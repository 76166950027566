import { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import TokenExpiryGuard from './components/TokenExpiryGuard';

import Header from './components/Header';
import Footer from './components/Footer';

import StyleGuide from './pages/StyleGuide';
import 'react-toastify/dist/ReactToastify.css'

import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Contacts from './pages/Contacts';
import SingleProduct from './pages/SingleProduct';
import Cart from './pages/Cart';
import Checkout from './pages/Checkout';
import Questions from './pages/Questions';
import AdminProducts from './pages/admin/AdminProducts';
import AdminOrders from './pages/admin/AdminOrders';
import OrderConfirmation from './pages/OrderConfirmation';
import AdminAddProduct from './pages/admin/AdminAddProduct';
import AdminAddQuestion from './pages/admin/AdminAddQuestion';
import Login from './pages/Login';
import Logout from './pages/Logout';
import AdminEditOrder from './pages/admin/AdminEditOrder';
import AdminEditCoupons from './pages/admin/AdminEditCoupons.jsx';
import AdminAddCoupon from './pages/admin/AdminAddCoupon.jsx';
import AdminQuestions from './pages/admin/AdminQuestions.jsx';
import AdminHarvest from './pages/admin/AdminHarvest.jsx';
import Instrukcija from './pages/Instrukcija.jsx';
import LietosanasNoteikumi from './pages/LietosanasNoteikumi.jsx';
import PrivatumaPolitika from './pages/PrivatumaPolitika.jsx';
import PiegadesNoteikumi from './pages/PiegadesNoteikumi.jsx';
import NotFound from './pages/NotFound.jsx';
import Processing from './pages/Processing.jsx';
import AdminStock from './pages/admin/AdminStock.jsx';
import Cookies from './components/Cookies.jsx';
import AdminInvoice from './pages/admin/AdminInvoice.jsx';
import AdminTexts from './pages/admin/AdminTexts.jsx';

function App() {
  const [cookiesAccepted, setCookiesAccepted] = useState(localStorage.getItem('cookiesAccepted'));

  useEffect(() => {
    if (cookiesAccepted === null) {
      localStorage.setItem('cookiesAccepted', 'false');
      setCookiesAccepted('false');
    } else {
      setCookiesAccepted(localStorage.getItem('cookiesAccepted'));
    }
  }, [cookiesAccepted]);


  return (
    <>
      <Router>
      <TokenExpiryGuard>
        <Header />
        {
          cookiesAccepted === 'false' && <Cookies setCookiesAccepted={setCookiesAccepted}/>
        }
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/contacts' element={<Contacts />} />
        </Routes>
        <div className='container'>
          <Routes>
            <Route path='/styles' element={<StyleGuide />} />

            <Route path='/checkout' element={<Checkout />} />
            <Route path='/cart' element={<Cart />} />

            <Route path='/product/:productId' element={<SingleProduct />} />

            <Route path='/edit-products' element={<AdminProducts />} />
            <Route path='/edit-products/trash' element={<AdminProducts trash={true} />} />
            <Route path='/edit-products/add' element={<AdminAddProduct />} />
            <Route path='/edit-products/:productId' element={<AdminAddProduct />} />
            <Route path='/enter-harvest' element={<AdminHarvest />} />
            <Route path='/edit-stock' element={<AdminStock />} />
            <Route path='/edit-invoice' element={<AdminInvoice />} />
            <Route path='/edit-texts' element={<AdminTexts />} />

            <Route path='/edit-questions' element={<AdminQuestions />} />
            <Route path='/edit-questions/add' element={<AdminAddQuestion />} />
            <Route path='/edit-questions/:questionId' element={<AdminAddQuestion />} />

            <Route path='/edit-orders' element={<AdminOrders />} />
            <Route path='/unpaid-orders' element={<AdminOrders unpaid={true}/>} />
            <Route path='/edit-orders/:orderId' element={<AdminEditOrder />} />

            <Route path='/instrukcija' element={<Instrukcija />} />
            <Route path='/jautajumi' element={<Questions />} />
            <Route path='/about' element={<AboutUs />} />

            <Route path='/lietosanas-noteikumi' element={<LietosanasNoteikumi />} />
            <Route path='/privatuma-politika' element={<PrivatumaPolitika />} />
            <Route path='/piegades-noteikumi' element={<PiegadesNoteikumi />} />

            <Route path='/edit-coupons' element={<AdminEditCoupons />} />
            <Route path='/edit-coupons/:couponId' element={<AdminAddCoupon />} />
            <Route path='/edit-coupons/new' element={<AdminAddCoupon />} />

            <Route path='/processing/:orderId' element={<Processing />} />
            <Route path='/order-confirmation/:orderId' element={<OrderConfirmation />} />
            
            <Route path='*' element={<NotFound />} />
            <Route path='/login' element={<Login />} />
            <Route path='/logout' element={<Logout />} />
          </Routes>
        </div>
        <Footer />
      </TokenExpiryGuard>
      </Router>
      <ToastContainer className="toast-position" autoClose={2000} />
    </>
  );
}

export default App;