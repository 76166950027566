import { Helmet } from 'react-helmet'

function PrivatumaPolitika() {
  return (
    <div className="noteikumi privatuma">
      <Helmet>
        <title>Nora Spora - Privātuma politika</title>
        <meta name="description" content="Izpētiet mūsu privātuma politiku lai uzzinātu kā mēs aizsargājam Jūsu personas datus un nodrošinām to drošību." />
        <meta name="keywords" content="privātuma politika, datu aizsardzība, personas dati, NORA SPORA" />
        <meta name="robots" content="index, follow" />
      </Helmet>
        <h2 className="light">Privātuma politika</h2>
        <ol>
            <li>VISPĀRĪGIE NOTEIKUMI
                <p>
                Šī "Konfidencialitātes politika" (turpmāk "Politika") attiecas uz visiem uzņēmuma SIA "NORA SPORA"(turpmāk "Mēs", "Mums", "Mūs") "Vietnes" (turpmāk "Vietne") "Lietotājiem" (tālāk tekstā - "Jūs", "Jums", "Jūsu"). Pirms pasūtījuma veikšanas uzmanīgi izlasiet Politiku. Laika gaitā mēs varam ieviest izmaiņas politikā, lūdzu, pārlasiet to pirms atkārtotas pirkumu veikšanas.
                <br /><br />
                Saskaņā ar vispārējiem datu aizsardzības noteikumiem personas dati ir jebkāda informācija, ko var izmantot, lai identificētu personu, kā arī jebkāda informācija par personu, kura jau ir identificēta. Izmantojot mūsu vietnes pakalpojumus, Jūs sniedzat mums savus personas datus, tiesības apstrādāt un izmantot tos saskaņā ar noteikumiem, kas izklāstīti Konfidencialitātes politikā un "Lietošanas noteikumos" (turpmāk "Noteikumi").
                <br /><br />
                Mēs apņemamies aizsargāt mūsu klientu un vietnes apeklētāju, tostarp Jūsu, privātumu un drošību. Izmantojot mūsu vietnes pakalpojumus Jūs izrādat mums uzticēšanos. Mēs to pilnībā novērtējam un cienām Jūsu datu privātuma un konfidecialitātes nozīmīgumu. Mēs ievērojam personas datu minimizēšanas principu – mēs nepieprasām, neapkopojam un neapstrādājam personas datus, kas nav nepieciešami, lai sasniegut mērķi – iegādāties preces, pakalpojumus vai saņemt informāciju. Mēs cienām Jūsu privātumu un daram visu iespējamo lai uzglabātu Jūsu personas datus drošībā. Mēs nepieļaujam nevēlamu vēstuļu nosūtīšanu un nekādā gadījumā nepieļausim jebkādu Jūsu personīgo datu (vārds, adrese, e-pasta adrese utt.) pārdošanu, iznomāšanu vai nodošanu trešajām personām. Informāciju trešājām personām var izpaust tikai, pamatojoties uz likumīga pieprasījuma, līguma vai ar Jūsu piekrišanas saņemšanu.
                <br /><br />
                Jūs esat atbildīgs par informācijas patiesumu, veicot pasūtījumu vai sazinoties ar klientu atbalsta dienestu. Jūs apliecināt, ka Jūs neesat jaunāks par 16 gadiem, kā arī apņematies neveikt nekādas darbības, kas vērstas pret vietnes drošību. Mēs neesam atbildīgi par nepareiziem, nepilnīgiem vai kļūdainiem Jūsu sniegtajiem datiem. Izmantojot mūsu tīmekļa vietni un / vai iegādājoties preces un / vai pakalpojumus, Jūs apliecināt, ka Jūs saprotat politiku, un Jūs piekrītat sekot izmaiņām tajā.
                <br /><br />
                Ja Jūs nepiekrītat šai politikai vai atsevišķām tajā ietvertām definīcijām, lūdzu, neizmantojiet mūsu vietnes pakalpojumus.
                </p>
            </li>

            <li>KĀDUS DATUS MĒS APKOPOJAM UN APSTRĀDĀJAM?
              <p>
                Veicot pirkumu vai sazinoties ar klientu atbalsta dienestu, Jūs sniedzat mums savu personīgo informāciju. Veicot pirkumu, personas datu iesniegšana ir obligāta, jo bez tā Jūs nevarēsiet pirkumu veikt.
                <br /><br />
                Mēs apkopojam un uzglabājam šādus datus:
              </p>
              <ul className="" style={{listStyle: "disc", paddingLeft: "20px", fontWeight: "400"}}>
                <li>klienta vārds un uzvārds;</li>
                <li>tālruņa numurs;</li>
                <li>e-pasta adrese;</li>
                <li>preču vai pakalpojumu piegādes adrese.</li>
              </ul>
              <p>
                <br />
                Privāto informāciju izmanto tikai, lai nodrošinātu pilnvērtīgu pakalpojumu sniegšanu un saziņai ar klientiem.
                <br /><br />
                Mēs varam saņemt Jūsu personīgo informāciju tikai no Jums un tikai tad, ja Jūs izmantojat mūsu vietnes pakalojumus, piemēram: pasūtījumu reģistrāciju, sazinaties ar klientu atbalstu, izmantojot atsauksmju veidlapu vai e-pastu.
              </p>
            </li>

            <li>MAKSĀJUMA INFORMĀCIJA
          <p>
            Maksājot par precēm un pakalpojumiem, ko sniedz SIA "NORA SPORA" vietne, mēs varam apkopot un apstrādāt šādu informāciju:
          </p>
          <ul style={{listStyle: "disc", paddingLeft: "20px", fontWeight: "400"}}>
            <li>Jūsu bankas konta numurs;</li>
            <li>bankas iestādes nosaukums;</li>
            <li>Jūsu tālruņa numurs;</li>
            <li>e-pasta adrese.</li>
          </ul>
          <p>
            Informāciju par Jūsu bankas norēķinu karti mēs nesaņemam un tā netiek apstrādāta.
          </p>
        </li>

        <li>KĀDEĻ MĒS APKOPOJAM JŪSU DATUS
          <p>
            Mēs izmantojam informāciju, ko apkopojam par Jums, lai:
          </p>
          <ul style={{listStyle: "disc", paddingLeft: "20px", fontWeight: "400"}}>
            <li>pārdodu mūsu preces un pakalpojumus;</li>
            <li>pareizi apstrādātu un piegādātu Jūsu pasūtījumu;</li>
            <li>nodrošinātu garantijas un pēcgarantijas atbalstu;</li>
            <li>atbildētu uz Jūsu jautājumiem un sniegtu Jums informāciju;</li>
            <li>sagatavotu anonīmu statistiku par vietnes izmantošanu.</li>
          </ul>
        </li>

        <li>CIK ILGI MĒS UZGLABĀJAM JŪSU DATUS
          <p>
            Klienta personisko informāciju mēs glabājam 10 gadus:
          </p>
          <ul style={{listStyle: "disc", paddingLeft: "20px", fontWeight: "400"}}>
            <li>lai nodrošinātu iegādāto preci ar garantijas un pēcgarantijas atbalstu;</li>
            <li>lai sazinātos ar Jums par pieprasījumu;</li>
            <li>grāmatvedībai.</li>
          </ul>
          <p>
            Pēc 10 gadu termiņa beigām, personas dati tiks iznīcināti, ja Jūs nepieprasīsit savu datu dzēšanu šajā periodā.
          </p>
        </li>

        <li>DATU NODOŠANA TREŠĀJĀM PERSONĀM
          <p>
            Jūsu dati var tikt nodoti trešajai personai, kas var būt - datu centri, atbalsta pakalpojumi, piegādes pakalpojumi, mākoņu glabāšanas pakalpojumu pārstāvji utt. Personīgo datu apstrādātāji, kas palīdz mums sniegt Jums pakalpojumus. Jebkurā gadījumā apstrādātājiem tiek nodots tikai tāds datu apjoms, kas nepieciešams konkrēta uzdevuma izpildei vai konkrēta pakalpojuma sniegšanai. Ar mums saistītās trešās personas var apstrādāt Jūsu personas datus tikai noteiktajiem uzdevumiem un tos nevar izmantot citiem mērķiem vai nodot citām personām bez mūsu piekrišanas. Apstrādātāji nodrošina Jūsu personas datu aizsardzību saskaņā ar spēkā esošajiem normatīvajiem aktiem.
          </p>
          <p>
            Personas dati var tikt nodoti kompetentajām valsts varas un pārvaldes iestādēm, tostarp tiesībsargājošajām iestādēm, piemēram, policijai vai citām uzraudzības iestādēm, bet tikai nepieciešamības gadījumā un saskaņā ar normatīvajos aktos noteikto tiesisko kārtību.
          </p>
        </li>

        <li>DATU NODOŠANA TREŠĀM VALSTĪM
          <p>
            SIA "NORA SPORA" nenodod personīgo informāciju un citus datus trešām valstīm.
          </p>
          <p>
            Personīgo un citu lietotāju datu izsniegšana tiek veikta tikai, pamatojoties uz likumīgu pieprasījumu.
          </p>
        </li>

        <li>LIETOTĀJA TIESĪBAS
          <p>
            Normatīvie akti par personas datu aizsardzību, tai skaitā Vispārīgā datu aizsardzības regula dod Jums virkni tiesību, savukārt, mums jānodrošina Jums iespēju tās izmantot.
          </p>
          <ul style={{listStyle: "disc", paddingLeft: "20px", fontWeight: "400"}}>
            <li>Jums ir tiesības pieprasīt Jūsu personiskās informācijas kopiju, lai pārbaudītu uzglabātās informācijas precizitāti un / vai labotu vai atjauninātu šos datus;</li>
            <li>jebkurā laikā Jūs varat atteikties saņemt e-pastus, nosūtot paziņojumu uz info@noraspora.lv;</li>
            <li>gadījumos, kad Jūsu dati tiek apstrādāti ar Jūsu piekrišanu, Jums ir tiesības jebkurā brīdī atsaukt savu piekrišanu, kas kalpo par pamatu datu apstrādes izbeigšanai;</li>
            <li>Jums ir tiesības saņemt Jūsu datu apstrādes apstiprinājumu, tiesības pārskatīt apstrādātos datus, iegūt informāciju par apstrādes mērķiem, apstrādes periodu, datu saņemšanas avotiem, to nozīmi un Jums no tā izrietošajām sekām;</li>
            <li>Jūs varat pieprasīt, lai Jūsu personiskā informācija tiktu pilnībā izdzēsta (ja tas nav pretrunā ar Latvijas Republikas likumdošanu).</li>
          </ul>
          <p>
            Lai aizsargātu Jūsu konfidencialitāti un drošību, mēs veiksim atbilstošus pasākumus, lai pārbaudītu Jūsu identitāti, pirms sniegt Jūsu pieprasīto informāciju par saviem personīgajiem datiem. Šim nolūkam mēs varam lūgt Jūs iesniegt personu apliecinošu dokumentu un / vai personīgi apmeklēt mūsu darbnīcu. Ja Jūs nevarat pierādīt savu identitāti, mēs varam noraidīt Jūsu pieteikumu.
          </p>
          <p>
            Mēs izmantosim visus komerciāli saprātīgos centienus, lai 30 dienu laikā pēc Jūsu piekļuves pieprasījuma izpildīšanas paziņotu Jums par piekļuves atteikuma iemesliem, ja tas ir attiecināms.
          </p>
          <p>
            Jums ir tiesības iebilst pret mums, apstrādājot Jūsu personas datus, ja mēs to darām likumīgi vai pamatojamies uz mūsu legitīmo interešu apstrādi. Jūsu iebildumi tiek apsvērti un apstrādāti saskaņā ar spēkā esošajiem tiesību aktiem.
          </p>
        </li>

        <li>JŪSU TIESĪBAS IZMANTOT ATBALSTU
          <p>
            Ja Jums ir jautājumi par šo privātuma politiku, kā arī par Jūsu datu izmantošanu, lūdzu, sazinieties ar mums info@noraspora.lv
          </p>
          <p>
            Katra jauna privātuma politikas versija, kas tiek izveidota, parādīsies šajā lapā. Pēdējās izmaiņas stājās spēkā 2018. gada 24. maijā.
          </p>
        </li>
        <li>IZMAIŅAS POLITIKĀ
          <p>
            Mēs regulāri pārskatām mūsu konfidencialitātes politiku. Visas izmaiņas tiks publicētas šajā lapā.
            Šī konfidencialitātes politika stājās spēkā un pēdējoreiz tika atjaunināta 2024. gada 20. jūnijā.
          </p>
        </li>

        </ol>
    </div>
  )
}

export default PrivatumaPolitika