import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProductsAdmin, reset } from '../../features/products/productSlice';
import { createCustomInvoice } from '../../features/orders/orderSlice';
import { toast } from 'react-toastify';
import Spinner from '../../components/Spinner';
import ButtonPrimary from '../../components/style-components/ButtonPrimary';

function AdminInvoice() {
  const dispatch = useDispatch();
  const { products, isLoading, isSuccess, isError, message } = useSelector((state) => state.product);

  const [productList, setProductList] = useState([]);

  const [freeShipping, setFreeShipping] = useState(false);
  const [percentageDiscount, setPercentageDiscount] = useState(0);
  const [amountDiscount, setAmountDiscount] = useState(0);

  const [customProductName, setCustomProductName] = useState('');
  const [customVariantName, setCustomVariantName] = useState('');
  const [customPrice, setCustomPrice] = useState('');
  const [customQuantity, setCustomQuantity] = useState(1);

  const [discountType, setDiscountType] = useState('percentage'); // 'percentage' or 'amount'

  useEffect(() => {
    dispatch(getProductsAdmin());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    return () => {
      dispatch(reset());
    };
  }, [isError, message, dispatch]);

  useEffect(() => {
    if (products.length > 0) {
      const expandedProductList = products.flatMap(product => 
        product.variants.map(variant => ({
          name: product.title,
          variant_name: variant.variant_name,
          price: variant.price,
          productId: product._id,
          variantId: variant._id,
        }))
      );
      setProductList(expandedProductList);
    }
  }, [products]);

  const generateOrderNum = () => {
    const random_num = Math.floor(Math.random() * 1000000);
    return `PAS9${random_num}`;
  }

  const [formData, setFormData] = useState({
    order_num: generateOrderNum(),
    createdAt: new Date().toISOString().split('T')[0],
    clientType: 'private',
    clientFirstName: '',
    clientLastName: '',
    companyName: '',
    companyRegistration: '',
    companyAddress: '',
    companyBankName: '',
    companyBankAccount: '',
    orderItems: [],
    itemPrice: 0,
    shippingPrice: 0,
    totalPrice: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleProductChange = (productId, variantId, e) => {
    const { name, value } = e.target;
    const newOrderItems = formData.orderItems.map(item => 
      item.productId === productId && item.variantId === variantId ? { ...item, [name]: value } : item
    );
    setFormData({
      ...formData,
      orderItems: newOrderItems,
    });
  };

  const handleProductSelect = (productId, variantId) => {
    const selectedProduct = formData.orderItems.find(item => item.productId === productId && item.variantId === variantId);
    const productDetails = productList.find(p => p.productId === productId && p.variantId === variantId);
    if (selectedProduct) {
      setFormData({
        ...formData,
        orderItems: formData.orderItems.filter(item => !(item.productId === productId && item.variantId === variantId)),
      });
    } else {
      setFormData({
        ...formData,
        orderItems: [...formData.orderItems, { 
          productId, 
          variantId, 
          name: productDetails.name, 
          variant_name: productDetails.variant_name, 
          price: productDetails.price, 
          quantity: 1 
        }],
      });
    }
  };

  useEffect(() => {
    const itemPrice = formData.orderItems.reduce((total, item) => {
      return total + (item.price * item.quantity);
    }, 0);

    if(itemPrice > 4000){ // 40 euros in cents
      setFreeShipping(true);
    } else {
      setFreeShipping(false);
    }

    const totalPrice = itemPrice + parseFloat(formData.shippingPrice);
    setFormData({
      ...formData,
      itemPrice,
      totalPrice,
    });
  }, [formData.orderItems, formData.shippingPrice]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    dispatch(createCustomInvoice({...formData, discount: getDiscount()}));
  };

  const calcTotalPrice = () => {
    let discount = 0;
    const itemPrice = formData.orderItems.reduce((total, item) => {
      return total + (item.price * item.quantity);
    }, 0);
    
    if(percentageDiscount > 0){
      discount = itemPrice * (percentageDiscount / 100);
    } else if (amountDiscount > 0){
      discount = amountDiscount * 100; // Convert to cents
    } else if (freeShipping){
      discount = formData.shippingPrice;
    }

    const totalPrice = itemPrice + parseFloat(formData.shippingPrice);
    if(discount > totalPrice){
      return 0;
    }
    return ((totalPrice - discount) / 100).toFixed(2); // Convert to euros
  }

  const getDiscount = () => {
    let discount = 0;
    const itemPrice = formData.orderItems.reduce((total, item) => {
      return total + (item.price * item.quantity);
    }, 0);
    
    if(percentageDiscount > 0){
      discount = itemPrice * (percentageDiscount / 100);
    } else if (amountDiscount > 0){
      discount = amountDiscount * 100; // Convert to cents
    } else if (freeShipping){
      discount = formData.shippingPrice;
    }

    const totalPrice = itemPrice + parseFloat(formData.shippingPrice);
    if(discount > totalPrice){
      return totalPrice;
    }
    return (discount / 100).toFixed(2); // Convert to euros
  }

  const formatPrice = (price) => {
    return (price / 100).toFixed(2);
  }

  const handleAddCustomProduct = () => {
    setFormData({
      ...formData,
      orderItems: [
        ...formData.orderItems,
        {
          productId: `custom-${Date.now()}`,
          variantId: `custom-${Date.now()}`,
          name: customProductName,
          variant_name: customVariantName,
          price: parseFloat(customPrice) * 100, // Convert to cents
          quantity: customQuantity,
        },
      ],
    });
    setCustomProductName('');
    setCustomVariantName('');
    setCustomPrice('');
    setCustomQuantity(1);
  };


  if (isLoading) return <Spinner />;

  return (
    <div className='custom-invoice'>
      <h2 className="light">Izveidot rēķinu</h2>
      <form onSubmit={handleSubmit}>
        <h5 className="light padding-20-top">Pasūtītāja informācija:</h5>
        <div>
          <label>Klienta tips:</label>
          <select name="clientType" value={formData.clientType} onChange={handleChange}>
            <option value="private">Privātpersona</option>
            <option value="legal">Juridiska persona</option>
          </select>
        </div>
        <div>
          <label>Pasūtījuma numurs:</label>
          <input type="text" name="order_num" value={formData.order_num} onChange={handleChange} placeholder="Ievadiet pasūtījuma numuru" />
        </div>
        <div>
          <label>Pasūtījuma datums:</label>
          <input type="date" name="createdAt" value={formData.createdAt} onChange={handleChange} placeholder="Izvēlieties datumu" />
        </div>
        {formData.clientType === 'private' ? (
          <>
            <div>
              <label>Pircēja vārds:</label>
              <input type="text" name="clientFirstName" value={formData.clientFirstName} onChange={handleChange} placeholder="Ievadiet vārdu" />
            </div>
            <div>
              <label>Pircēja uzvārds:</label>
              <input type="text" name="clientLastName" value={formData.clientLastName} onChange={handleChange} placeholder="Ievadiet uzvārdu" />
            </div>
          </>
        ) : (
          <>
            <div>
              <label>Pircēja vārds:</label>
              <input type="text" name="clientFirstName" value={formData.clientFirstName} onChange={handleChange} placeholder="Ievadiet vārdu" />
            </div>
            <div>
              <label>Pircēja uzvārds:</label>
              <input type="text" name="clientLastName" value={formData.clientLastName} onChange={handleChange} placeholder="Ievadiet uzvārdu" />
            </div>
            <div>
              <label>Uzņēmuma nosaukums:</label>
              <input type="text" name="companyName" value={formData.companyName} onChange={handleChange} placeholder="Ievadiet uzņēmuma nosaukumu" />
            </div>
            <div>
              <label>Uzņēmuma reģistrācijas numurs:</label>
              <input type="text" name="companyRegistration" value={formData.companyRegistration} onChange={handleChange} placeholder="Ievadiet reģistrācijas numuru" />
            </div>
            <div>
              <label>Uzņēmuma adrese:</label>
              <input type="text" name="companyAddress" value={formData.companyAddress} onChange={handleChange} placeholder="Ievadiet uzņēmuma adresi" />
            </div>
            <div>
              <label>Uzņēmuma bankas nosaukums:</label>
              <input type="text" name="companyBankName" value={formData.companyBankName} onChange={handleChange} placeholder="Ievadiet bankas nosaukumu" />
            </div>
            <div>
              <label>Uzņēmuma bankas konts:</label>
              <input type="text" name="companyBankAccount" value={formData.companyBankAccount} onChange={handleChange} placeholder="Ievadiet bankas kontu" />
            </div>
          </>
        )}
        <h5 className="light padding-20-top">Pasūtījuma saturs:</h5>
        <div className="product-picker">
          {productList.map((product, index) => (
            <div key={`${product.productId}-${product.variantId}`} className="product-choice">
              <input 
                type="checkbox" 
                name="product" 
                value={product.variantId} 
                onChange={() => handleProductSelect(product.productId, product.variantId)} 
                checked={formData.orderItems.some(item => item.productId === product.productId && item.variantId === product.variantId)}
              />
              <p>{product.name}</p>
              {formData.orderItems.some(item => item.productId === product.productId && item.variantId === product.variantId) && (
                <input 
                  type="number" 
                  name="quantity" 
                  value={formData.orderItems.find(item => item.productId === product.productId && item.variantId === product.variantId).quantity} 
                  onChange={(e) => handleProductChange(product.productId, product.variantId, e)} 
                  placeholder="Ievadiet daudzumu" 
                />
              )}
            </div>
          ))}
        </div>
        <div className="product-picker padding-20-top flex column gap-10">
          <h5 className="light">Pievienot "custom" produktu</h5>
          <div className="flex column">
            <p>Nosaukums:</p>
            <input
              type="text"
              placeholder="Produkta nosaukums:"
              className='basic-input'
              value={customProductName}
              onChange={(e) => setCustomProductName(e.target.value)}
            />
          </div>
          
          <div className="flex column">
            <p>Nosaukums:</p>
            <input
              type="text"
              placeholder="Izmēra nosaukums"
              className='basic-input'
              value={customVariantName}
              onChange={(e) => setCustomVariantName(e.target.value)}
            />
          </div>

          <div className="flex column">
            <p>Nosaukums:</p>
            <input
              type="number"
              placeholder="Cena"
              className='basic-input'
              value={customPrice}
              onChange={(e) => setCustomPrice(e.target.value)}
            />
          </div>
          <div className="flex column">
            <p>Nosaukums:</p>
            <input
              type="number"
              placeholder="Daudzums"
              className='basic-input'
              value={customQuantity}
              onChange={(e) => setCustomQuantity(e.target.value)}
            />
          </div>
          <ButtonPrimary text='Pievienot' onClick={handleAddCustomProduct} />
        </div>
        <div className="product-picker padding-10-top">
          <h5 className="light">Izvēlētie produkti:</h5>
          <ul className='flex column gap-10 padding-10-top'>
            {(formData.orderItems && formData.orderItems.length > 0) ? formData.orderItems.map((item, index) => (
              <li key={index}><b>{item.quantity}x</b> {item.name} - €{formatPrice(item.price)}</li>
            )) : (
              <li>Nav izvēlētu produktu</li>
            )}
          </ul>
        </div>
        <div className="discount padding-20-top flex column gap-10">
          <h5 className='light'>Atlaide:</h5>
          <div className='flex row gap-10'>
            <input 
              type="radio" 
              id="amount" 
              name="discountType" 
              value="amount" 
              style={{height: '1rem'}}
              checked={discountType === 'amount'} 
              onChange={() => {
                setDiscountType('amount');
              }}
            />
            <label htmlFor="amount">Summa</label>
          </div>
          <div className='flex row gap-10'>
            <input 
              type="radio" 
              id="percentage" 
              name="discountType" 
              value="percentage" 
              style={{height: '1rem'}}
              checked={discountType === 'percentage'} 
              onChange={() => {
                setDiscountType('percentage');
              }}
            />
            <label htmlFor="percentage">Procentuāla</label>
          </div>
          
        </div>

        {discountType === 'percentage' && (
          <div className="product-choice discount">
            <p>ATLAIDES VĒRTĪBA</p>
            <input 
              type="number" 
              name="percentageDiscount" 
              value={percentageDiscount}
              onChange={(e) => setPercentageDiscount(e.target.value)}
              placeholder="Ievadiet daudzumu" 
            />
            <p>%</p>
          </div>
        )}

        {discountType === 'amount' && (
          <div className="product-choice discount">
            <p>ATLAIDES VĒRTĪBA</p>
            <input 
              type="number" 
              name="amountDiscount" 
              value={amountDiscount}
              onChange={(e) => setAmountDiscount(e.target.value)}
              placeholder="Ievadiet daudzumu" 
            />
            <p>€</p>
          </div>
        )}
        <div>
          <label>Preču cena:</label>
          <input disabled type="number" name="itemPrice" value={formatPrice(formData.itemPrice)} placeholder="Ievadiet preču cenu" />
        </div>
        <div>
          <label>Piegādes maksa (centos):</label>
          <input type="number" name="shippingPrice" value={formData.shippingPrice} onChange={handleChange} placeholder="Ievadiet piegādes maksu" />
        </div>
        <div>
          <label>Atlaide:</label>
          <input disabled type="number" name="totalPrice" value={getDiscount()} />
        </div>
        <div>
          <label>Kopējā summa:</label>
          <input disabled type="number" name="totalPrice" value={calcTotalPrice()} placeholder="Ievadiet kopējo summu" />
        </div>
        <button type="submit" >Izveidot rēķinu</button>
      </form>
    </div>
  );
}

export default AdminInvoice;