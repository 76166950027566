import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isTokenExpired, clearAuthData } from '../utils/auth';

const TokenExpiryGuard = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isTokenExpired()) {
      clearAuthData();
      navigate('/login');
    }
  }, [navigate]);

  return children;
};

export default TokenExpiryGuard;