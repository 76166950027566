import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FaTimes, FaAngleUp, FaAngleDown } from 'react-icons/fa';
import { removeFromCart, updateCart, validateCoupon, removeCoupon, reset } from '../features/products/productSlice';
import ButtonPrimary from '../components/style-components/ButtonPrimary';
import placeholder_image from '../img/placeholder-image.jpeg';
import Spinner from '../components/Spinner';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

function Cart() {
  const [cartItems, setCartItems] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);
  const [discountObject, setDiscountObject] = useState(null);

  const { cart, discount, isLoading, isError, message } = useSelector((state) => state.product);
  const [discountCode, setDiscountCode] = useState('');

  const [mobile, setMobile] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth < 1100);
    };

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Cleanup function
    return () => {
      // Remove the event listener
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const storedCartItems = JSON.parse(localStorage.getItem('cart'));
    
    if (storedCartItems) {
      // Parse and group the stored cart items
      const parsedItems = storedCartItems.map(item => JSON.parse(item));
      const groupedItems = groupCartItems(parsedItems);
      setCartItems(groupedItems);
    } 
  }, [cart]);

  useEffect(() => {
    const storedDiscount = JSON.parse(localStorage.getItem('discount'));
    if (storedDiscount) {
      setDiscountObject(storedDiscount);
      setDiscountCode(storedDiscount.code);
    }
  }, [discount]);

  const groupCartItems = (items) => {
    const itemMap = {};
    items.forEach(item => {
      
      const key = `${item._id}-${item.variant._id}`;
      if (itemMap[key]) {
        itemMap[key].qty += 1;
      } else {
        itemMap[key] = { ...item, qty: 1 };
      }
    });

    return Object.values(itemMap);
  };

  const handleRemoveFromCart = (item) => {
    dispatch(removeFromCart(item));
  }

  const updateOriginalCart = (updatedCart) => {
    const originalFormat = updatedCart.flatMap(item => Array(item.qty).fill(JSON.stringify(item)));
    dispatch(updateCart(originalFormat));
  }

  const handleQuantityChange = (item, newQty) => {
    if (newQty > item.variant.countInStock) {
      toast.error(`Noliktavā pieejamais skaits: ${item.variant.countInStock}`);
      return;
    }
    
    if(newQty < 1){
      handleRemoveFromCart(item);
      return;
    }

    const updatedItem = { ...item, qty: newQty };
    const updatedCart = cartItems.map(cartItem => {
      if (cartItem._id === item._id && cartItem.variant._id === item.variant._id) {
        return updatedItem;
      }
      return cartItem;
    });

    updateOriginalCart(updatedCart);
    setCartItems(updatedCart);
  };

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
    return () => {
      dispatch(reset());
    }
  }, [discount, isError, isLoading, message, dispatch]);

  useEffect(() => {
    setCartTotal(cartItems.reduce((acc, item) => acc + item.qty * (item.variant.discount_price ? item.variant.discount_price : item.variant.price), 0));
  }, [cartItems]);

  const handleValidateCoupon = () => {
    if (discountCode === '') {
      toast.error('Lūdzu ievadiet kuponu');
      return;
    }
    const cartIds = cartItems.map(item => item._id);
    const data = { code: discountCode, productIds: cartIds };
    dispatch(validateCoupon(data));
  }

  const handleCouponRemoval = () => {
    setDiscountObject(null);
    setDiscountCode('');
    dispatch(removeCoupon());
  }

  const calculateDiscountedTotal = (cartTotal, discountObject) => {
    let discountedTotal = cartTotal;

    if (discountObject) {
        if (discountObject.discountAmount) {
            discountedTotal = cartTotal - discountObject.discountAmount;
        } else if (discountObject.discountPercentage) {
            discountedTotal = cartTotal * ((100 - discountObject.discountPercentage) / 100);
        }
    }

    // Ensure the total is not negative
    discountedTotal = Math.max(discountedTotal, 0);

    return (discountedTotal / 100).toFixed(2);
  };

  const formatPrice = (price) => (price / 100).toFixed(2);

  if (isLoading) return <Spinner />

  return (
    <div className='cart padding-40-bottom'>
      <Helmet>
        <title>Nora Spora - Grozs</title>
        <meta name="description" content="Nora Spora piedāvā plašu klāstu sēņu audzēšanas komplektu, tostarp rozā un pelēkās austersēnes, ežu dižadatenes, gļotainās zvīņenes un drīzumā arī citas." />
        <meta name="keywords" content="nora, spora, sporas, noraspora, supersenes, supersēnes, super, sēnes, maple garden, kļavas dārzs, svaigas sēnes, Trubenieki, Eco Fungus, Austersēnes, Sēņu laboratorija Spora, baravikas, gailenes, bekas, micēlijs, micēlija tapiņas, micēlija dībeļi, rozā austersēnes, austeres, pelēkās austersēnes, zilās austersēnes, lions mane, ežu dižadatene, reiši, reishi, shitake, šitake, šitaki, dzeltenās austersēnes, zeltainās austersēnes, zelta austersēnes, dāvana, dāvanas, hobijs, ekoloģisks, bioloģisks, bio, eko, dabīgs, latvijas sēnes" />
      </Helmet>
      <h2 className='light'>Grozs</h2>
      <table className='cart-table'>
        <thead>
          <tr className='table-head'>
            <th></th>
            <th>Produkts</th>
            <th>Cena</th>
            <th>Daudzums</th>
            <th>Kopā</th>
            <th className='remove'></th>
          </tr>
        </thead>
        <tbody>
          {cartItems.map((item, index) => (
            <tr key={index}>
              <td className="image"><a href={"product/"+item._id}><img src={item.image ? item.image.src : placeholder_image} alt={item.title} /></a></td>
              {mobile ? 
                <td className='title'><a href={"product/"+item._id}>{item.title}</a></td>
              :
                <td className='title'><a href={"product/"+item._id}>{item.title}, {item.category}</a></td>  
              }
              
              <td className='price'>{item.variant.discount_price ? formatPrice(item.variant.discount_price) : formatPrice(item.variant.price)} €</td>
              <td className='quantity'>
                <div className="quantity-container">
                  <span>{item.qty}</span>
                  <div className="quantity-controls">
                    <FaAngleUp size={18} style={{cursor:"pointer"}} onClick={() => handleQuantityChange(item, item.qty + 1)} />
                    <FaAngleDown size={18} style={{cursor:"pointer"}} onClick={() => handleQuantityChange(item, item.qty - 1)} />
                  </div>
                </div>
                
              </td>
              <td className='total'>{formatPrice(item.variant.discount_price ? item.qty * item.variant.discount_price : item.qty * item.variant.price)} €</td>
              <td className='remove'><FaTimes className="cart-remove-button" size={20} onClick={() => handleRemoveFromCart(item)} /></td>
          
            </tr>
          ))}
        </tbody>
      </table>
      <div className='cart-summary padding-20-top'>
        <div className="cart-summary-info">
          <p>Produktu summa: </p>
          <p>{formatPrice(cartItems.reduce((acc, item) => acc + item.qty * (item.variant.discount_price ? item.variant.discount_price : item.variant.price), 0))}€</p>
        </div>
        {discountObject ? 
          <div className="cart-summary-info">
          <p>Atlaide: </p>
          <p>{discountObject ? discountObject.discountAmount ? `${(discountObject.discountAmount / 100).toFixed(2)}€` : `${discountObject.discountPercentage}%` : 0}</p>
        </div>
        : null  
        }
        
        <div className="cart-summary-info">
          <p>Kopā: </p>
          <p>{calculateDiscountedTotal(cartTotal, discountObject)}€</p>
          </div>
      </div>
      <div className="cart-add-coupon flex padding-20-top" >
        <input placeholder="Kupona kods" type="text" value={discountCode} onChange={(e) => setDiscountCode(e.target.value)} />
        <ButtonPrimary text={discountObject ? 'Noņemt kuponu' : 'Pievienot kuponu'} onClick={discountObject ? handleCouponRemoval : handleValidateCoupon} />
      </div>
      {cartItems.length > 0 ?
        <div className="flex padding-20-top" >
          <ButtonPrimary text='Noformēt pasūtījumu' onClick={() => navigate("/checkout")} />
        </div>
        :
        <div className="padding-20-top">
          Grozs ir tukšs
        </div>}
    </div>
  );
}

export default Cart;