import { useState, useEffect } from 'react';
import ButtonPrimary from '../components/style-components/ButtonPrimary';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { initiateStripe } from '../features/orders/orderSlice';
import { getOmnivaLocations } from '../features/shipping/shippingSlice';
import OmnivaSelect from '../components/OmnivaSelect';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import SmartpostSelect from '../components/SmartpostSelect';
import ShippingSelection from '../components/ShippingSelection';

function Checkout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [discountObject, setDiscountObject] = useState(null);

  const [juridiskaPersona, setJuridiskaPersona] = useState(false);

  const DELIVERY_PRICE_UNDER_10KG = 299;

  const [formData, setFormData] = useState({
    user_name: '',
    user_surname: '',
    shippingAddress: '',
    shippingCity: '',
    shippingPostcode: '',
    user_phone: '',
    user_email: '',
    company_name: '',
    company_code: '',
    company_address: '',
    company_bank: '',
    company_bank_account: '',
    agree_terms: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const [cartItems, setCartItems] = useState([]);
  const { cart, discount } = useSelector((state) => state.order);

  const [omnivaSelected, setOmnivaSelected] = useState(true);
  const [selectedShipping, setSelectedShipping] = useState('smartpost');

  const [selectedPickup, setSelectedPickup] = useState(null);
  const [selectedSmartpost, setSelectedSmartpost] = useState(null);

  const [selectedShippingMethod, setSelectedShippingMethod] = useState('smartpost');
  const [selectedPickupObject, setSelectedPickupObject] = useState(null);
  const [selectedShippingZip, setSelectedShippingZip] = useState('')

  useEffect(() => {
    dispatch(getOmnivaLocations());
  }, [dispatch]);

  const handleCheckboxClick = (e) => {
    const { name, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: checked,
    }));
  };
  

  useEffect(() => {
    const storedCartItems = JSON.parse(localStorage.getItem('cart'));
    if (storedCartItems) {
      const parsedItems = storedCartItems.map(item => JSON.parse(item));
      const groupedItems = groupCartItems(parsedItems);
      setCartItems(groupedItems);
    }
    if (!storedCartItems) {
      navigate('/');
      toast.error('Kaut kas nogāja greizi.');
    }
  }, [cart, navigate]);

  useEffect(() => {
    const storedDiscount = JSON.parse(localStorage.getItem('discount'));
    if (storedDiscount) {
      setDiscountObject(storedDiscount);
    }
  }, [discount]);

  const groupCartItems = (items) => {
    const itemMap = {};

    items.forEach(item => {
      const key = `${item._id}-${item.variant._id}`;
      if (itemMap[key]) {
        itemMap[key].qty += 1;
      } else {
        itemMap[key] = { ...item, qty: 1 };
      }
    });

    return Object.values(itemMap);
  };

  // const calculateTotalWeight = () => {
  //   return cartItems.reduce((total, item) => total + item.variant.weight * item.qty, 0);
  // };

  const getDeliveryPrice = () => {
    // const totalWeight = calculateTotalWeight();
    return (DELIVERY_PRICE_UNDER_10KG / 100).toFixed(2);
  };

  const getFreeDelivery = () => {
    const price = cartItems.reduce((acc, item) => acc + item.qty * (item.variant.discount_price ? item.variant.discount_price : item.variant.price), 0) >= 4000;
    const coupon = discountObject ? discountObject.delivery? true : false : false;
    return price || coupon;
  };

  const validatePhone = (phone) => {
    // Regex pattern to match:
    // 1. An 8-digit number without a prefix.
    // 2. An 8-digit number with prefix +371.
    // 3. An 8-digit number with prefix 371.
    const phoneRegex = /^(?:\+371|371)?\s?\d{8}$/;
    return phoneRegex.test(phone);
};

  const validateForm = () => {
    let isValid = true;

    if (formData.user_name === '') {
      toast.error('Lūdzu ievadiet vārdu');
      isValid = false;
    } else if (formData.user_surname === '') {
      toast.error('Lūdzu ievadiet uzvārdu');
      isValid = false;
    } 

    // else if (formData.shippingAddress === '') {
    //   toast.error('Lūdzu ievadiet piegādes adresi');
    //   isValid = false;
    // } else if (formData.shippingCity === '') {
    //   toast.error('Lūdzu ievadiet piegādes pilsētu');
    //   isValid = false;
    // } else if (formData.shippingPostcode === '') {
    //   toast.error('Lūdzu ievadiet piegādes pasta indeksu');
    //   isValid = false;
    // } 
    
    else if (formData.user_phone === '') {
      toast.error('Lūdzu ievadiet telefona numuru');
      isValid = false;
    } else if (formData.user_email === '') {
      toast.error('Lūdzu ievadiet epasta adresi');
      isValid = false;
    } else if (!selectedShippingZip || isNaN(Number(selectedShippingZip))) {
      console.log(selectedShippingZip)
      toast.error('Lūdzu izvēlieties piegādes metodi no saraksta');
      isValid = false;
    } else if (!validatePhone(formData.user_phone)) {
      toast.error('Nepareizi ievadīts telefona numurs (piemērs: +371 12345678)');
      isValid = false;
    } else if(juridiskaPersona && formData.company_name === '') {
      toast.error('Lūdzu ievadiet uzņēmuma nosaukumu');
      isValid = false;
    } else if(juridiskaPersona && formData.company_code === '') {
      toast.error('Lūdzu ievadiet uzņēmuma reģ. numuru');
      isValid = false;
    } else if(juridiskaPersona && formData.company_address === '') {
      toast.error('Lūdzu ievadiet uzņēmuma adresi');
      isValid = false;
    } else if(juridiskaPersona && formData.company_bank === '') {
      toast.error('Lūdzu ievadiet uzņēmuma bankas nosaukumu');
      isValid = false;
    } else if(juridiskaPersona && formData.company_bank_account === '') {
      toast.error('Lūdzu ievadiet uzņēmuma bankas konta numuru');
      isValid = false;
    } else if (!formData.agree_terms) {
      toast.error('Lūdzu apstipriniet, ka piekrītat lietošanas noteikumiem');
      isValid = false;
    }
    
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    const order_object = {
      ...formData,
      pickup: selectedPickup,
      orderItems: cartItems,
      coupon: discountObject ? discountObject._id : null,
      shipping_method: selectedShippingMethod,
      pickup_object: selectedPickupObject,
    };
    
    dispatch(initiateStripe(order_object));
  };

  function calculateTotal(cartItems, discountObject, omnivaSelected, getDeliveryPrice, getFreeDelivery) {
    // Calculate the total price of items in the cart considering any item-specific discounts
    let itemTotal = cartItems.reduce((acc, item) => {
      const price = item.variant.discount_price ? item.variant.discount_price : item.variant.price;
      return acc + item.qty * price;
    }, 0);
  
    // Calculate the delivery price if applicable
    let deliveryTotal = omnivaSelected && !getFreeDelivery() ? DELIVERY_PRICE_UNDER_10KG : 0;
  
    // Calculate the discount amount
    let discountTotal = 0;
    if (discountObject) {
      if (discountObject.discountAmount) {
        discountTotal = discountObject.discountAmount;
      } else if (discountObject.discountPercentage) {
        discountTotal = itemTotal * (discountObject.discountPercentage / 100);
      }
    }
  
    // Calculate the final total and ensure it is not negative
    let finalTotal = Math.max(0, itemTotal + deliveryTotal - discountTotal);
  
    return (finalTotal / 100).toFixed(2);
  }

  const calculateProductPrice = (cartItems) => {
    const total = cartItems.reduce((acc, item) => acc + item.qty * (item.variant.discount_price ? item.variant.discount_price : item.variant.price), 0);
    return (total / 100).toFixed(2) + '€';
  }

  const getProductPrice = (item) => {
    const price = item.variant.discount_price ? item.variant.discount_price : item.variant.price;
    return (price * item.qty / 100).toFixed(2) + '€';
  }

  const getDiscountAmount = (discountObject) => {
    return discountObject.discountAmount ? (discountObject.discountAmount / 100).toFixed(2) + '€' : discountObject.discountPercentage + '%';
  }
  

  return (
    <div className="checkout-page padding-40-bottom">
      <Helmet>
        <title>Nora Spora - Pasūtījums</title>
        <meta name="description" content="Nora Spora piedāvā plašu klāstu sēņu audzēšanas komplektu, tostarp rozā un pelēkās austersēnes, ežu dižadatenes, gļotainās zvīņenes un drīzumā arī citas." />
        <meta name="keywords" content="nora, spora, sporas, noraspora, supersenes, supersēnes, super, sēnes, maple garden, kļavas dārzs, svaigas sēnes, Trubenieki, Eco Fungus, Austersēnes, Sēņu laboratorija Spora, baravikas, gailenes, bekas, micēlijs, micēlija tapiņas, micēlija dībeļi, rozā austersēnes, austeres, pelēkās austersēnes, zilās austersēnes, lions mane, ežu dižadatene, reiši, reishi, shitake, šitake, šitaki, dzeltenās austersēnes, zeltainās austersēnes, zelta austersēnes, dāvana, dāvanas, hobijs, ekoloģisks, bioloģisks, bio, eko, dabīgs, latvijas sēnes" />
      </Helmet>
      <div className="checkout">
        <h2 className='light'>Pasūtījuma noformēšana</h2>
        <div className="clientType flex gap-10 padding-10-top">
          <button className={`selectButton ${!juridiskaPersona ? "selected" : ""}`} onClick={() => setJuridiskaPersona(false)}>Privāta persona</button>
          <button className={`selectButton ${juridiskaPersona ? "selected" : ""}`} onClick={() => setJuridiskaPersona(true)}>Juridiska persona</button>
        </div>
        <form onSubmit={handleSubmit} className='checkout-form padding-20-top'>
          <h4>Pasūtītāja informācija</h4>
          <div className="split-input">
            <input
              type="text"
              name="user_name"
              className='split-input'
              placeholder="Vārds *"
              value={formData.user_name}
              onChange={handleInputChange}
              required
            />
            <input
              type="text"
              name="user_surname"
              placeholder="Uzvārds *"
              value={formData.user_surname}
              onChange={handleInputChange}
              required
            />
          </div>
          {juridiskaPersona &&
          <>
            <input
            type="text"
            name="company_name"
            placeholder="Uzņēmuma nosaukums *"
            value={formData.company_name}
            onChange={handleInputChange}
            required
          />
          <input
            type="text"
            name="company_code"
            placeholder="Uzņēmuma Reģ. Nr. *"
            value={formData.company_code}
            onChange={handleInputChange}
            required
          />
          <input
            type="text"
            name="company_address"
            placeholder="Uzņēmuma adrese *"
            value={formData.company_address}
            onChange={handleInputChange}
            required
          />
          <input
            type="text"
            name="company_bank"
            placeholder="Uzņēmuma bankas nosaukums *"
            value={formData.company_bank}
            onChange={handleInputChange}
            required
          />
          <input
            type="text"
            name="company_bank_account"
            placeholder="Uzņēmuma bankas konta numurs *"
            value={formData.company_bank_account}
            onChange={handleInputChange}
            required
          />
          </>
          
          }

          {/* <h4 className='padding-20-top'>Maksātāja adrese</h4>
          <input
            type="text"
            name="shippingAddress"
            placeholder="Adrese *"
            value={formData.shippingAddress}
            onChange={handleInputChange}
            required
          />
          <input
            type="text"
            name="shippingCity"
            placeholder="Pilsēta *"
            value={formData.shippingCity}
            onChange={handleInputChange}
            required
          />
          <input
            type="text"
            name="shippingPostcode"
            placeholder="Pasta indekss *"
            value={formData.shippingPostcode}
            onChange={handleInputChange}
            required
          /> */}

          <h4 className='padding-20-top'>Kontaktinformācija</h4>
          <input
            type="tel"
            name="user_phone"
            placeholder="Tālrunis (reģistrēts Latvijā) *"
            value={formData.user_phone}
            onChange={handleInputChange}
            required
          />
          <input
            type="email"
            name="user_email"
            placeholder="Epasta adrese *"
            value={formData.user_email}
            onChange={handleInputChange}
            required
          />
        </form>
      </div>
      <div className="order-summary">
        <h4>Jūsu pasūtījums: </h4>
        <ul>
          {cartItems.map((item, index) => (
            <li key={index}>
              {item.qty} x {item.title}, {item.category} - {getProductPrice(item)}
            </li>
          ))}
        </ul>
        {/* {cartItems.map((item, index) => (
          <div key={index} className='checkout-order-item padding-20-top'>
            <p>{item.qty} x {item.title} ({item.variant.variant_name}), {item.category} - €{(item.variant.price * item.qty).toFixed(2)}</p>
          </div>
        ))} */}
        <ShippingSelection formData={formData} setFormData={setFormData} selectedShippingMethod={selectedShippingMethod} setSelectedShippingMethod={setSelectedShippingMethod} setSelectedPickupObject={setSelectedPickupObject} setSelectedShippingZip={setSelectedShippingZip}/>

        <div className="cart-summary checkout" style={{ width: "100%" }}>
          <div className="cart-summary-info">
            <p>Summa: </p>
            <p>{calculateProductPrice(cartItems)}</p>
          </div>
          {omnivaSelected && 
            <div className="cart-summary-info">
              <p>Piegāde: </p>
              <p>{getFreeDelivery() ? 'Bezmaksas' : `${getDeliveryPrice()}€`}</p>
            </div>
          }
          {discountObject && 
          <div className="cart-summary-info">
          <p>Atlaide: </p>
          <p>{getDiscountAmount(discountObject)}</p>
        </div>
          }
          <div className="cart-summary-info">
            <p>Kopā: </p>
            <p>
              {calculateTotal(cartItems, discountObject, omnivaSelected, getDeliveryPrice, getFreeDelivery)}€
            </p>
          </div>

        </div>
        <div className="flex padding-20-top padding-5-bottom">
          <input
            type="checkbox"
            name="agree_terms"
            checked={formData.agree_terms}
            onChange={() => {}}
            onClick={handleCheckboxClick}
          />
          <span className="padding-10-left">Piekrītu <a href="/lietosanas-noteikumi" className='blue-link' target='_blank'>lietošanas noteikumiem</a></span>
        </div>
        <div className="checkout-button" onClick={handleSubmit}>
          <ButtonPrimary text="Veikt pasūtījumu" />
        </div>
      </div>
    </div>
  );
}

export default Checkout;
