import { FaFacebookSquare, FaInstagram  } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-container">
        <ul className="left">
            <li><a href="/privatuma-politika">Privātuma politika</a></li>
            <li><a href="/lietosanas-noteikumi">Lietošanas noteikumi</a></li>
            <li><a href="/piegades-noteikumi">Piegādes noteikumi</a></li>
          </ul>
          <ul className="center">
            {/* <li><a href="/#veikals">Veikals</a></li>
            <li><a href="/instrukcija">Instrukcija</a></li>
            <li><a href="/questions">Jautājumi</a></li> */}
            <li>SIA "Nora Spora"</li>
            <li className="italic">Reģ. Nr. 40203592112; "Vilnīši", Madlienas pagasts, Ogres novads, LV-5045; info@noraspora.lv</li>
          </ul>
          <ul className="right">
            <li><a href="https://www.instagram.com/nora.spora/" target="_blank" ><FaInstagram size={50}/></a></li>
            <li><a href="https://www.facebook.com/noraspora.lv/" target="_blank"><FaFacebookSquare size={50}/></a></li>
          </ul>
          
      </div>
      <ul className="mobile">
          <li>©NoraSpora 2024</li>
          <li className="italic">Reģ. Nr. 40002210344; "Vilnīši", Madlienas pagasts, Ogres novads, LV-5045; info@noraspora.lv</li>
        </ul>
    </div>
  )
}

export default Footer
