import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { createQuestion, updateQuestion, getQuestionById, reset } from '../../features/questions/questionSlice'
import { toast } from 'react-toastify'
import ButtonPrimary from '../../components/style-components/ButtonPrimary'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


function AdminAddQuestion() {
    const [questionText, setQuestion] = useState('')
    const [answer, setAnswer] = useState('')
    const [category, setCategory] = useState('')

    const { questionId } = useParams()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { question, isLoading, isError, message } = useSelector((state) => state.question)

    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
      if (!user) {
        navigate('/');
      }
    }, [user, navigate]);

    useEffect(() => {
      if (isError) {
        toast.error(message)
      }

      if(question) {
        setQuestion(question.question)
        setAnswer(question.answer)
        setCategory(question.category)
      }

      return () => {
        dispatch(reset());
      };
    }, [question, isLoading, isError, message, dispatch]);

    useEffect(() => {
      if(questionId && questionId !== 'add'){
        dispatch(getQuestionById(questionId))
      }
    }, [questionId])

    const handleSubmit = (e) => {
        e.preventDefault()
        if(questionId && questionId !== 'add') {
          dispatch(updateQuestion({_id: questionId, question: questionText, answer, category}))
        } else {
          dispatch(createQuestion({question: questionText, answer, category}))
        }
        
        navigate('/edit-questions')
    }

  return (
    <div className='add-question padding-40-bottom'>
      <ButtonPrimary onClick={() => navigate('/edit-questions')}>Atpakaļ</ButtonPrimary>
      <h2 className='light padding-20-top'>Pievienot jaunu jautājumu</h2>
      <form action="POST" className='padding-20-top'>
        <input placeholder="Jautājums" type="text" value={questionText} onChange={(e) => setQuestion(e.target.value)}/>
        <input placeholder="Kategorija" type="text" value={category} onChange={(e) => setCategory(e.target.value)}/>
        {/* <textarea placeholder="Atbilde" value={answer} onChange={(e) => setAnswer(e.target.value)}></textarea> */}
        <div className="input-area">
            <ReactQuill height={100} placeholder="Atbilde" className="my-quill" value={answer} onChange={setAnswer} />
        </div>
        
        <div className="flex">
            <ButtonPrimary onClick={handleSubmit}>{questionId ? "Saglabāt" : "Pievienot"}</ButtonPrimary>

        </div>
      </form>
    </div>
  )
}

export default AdminAddQuestion
