
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import shippingService from './shippingService'

const initialState = {
  omniva_locations: [],
  smartpost_locations: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Get Omniva locations
export const getOmnivaLocations = createAsyncThunk('locations/omniva', async (order, thunkAPI) => {
  try {
    return await shippingService.getOmnivaLocations()
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// Get Smartpost locations
export const getSmartpostLocations = createAsyncThunk('locations/smartpost', async (order, thunkAPI) => {
  try {
    return await shippingService.getSmartpostLocations()
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const shippingSlice = createSlice({
    name: 'shipping',
    initialState,
    reducers: {
      reset: (state) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = false
        state.message = ''
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(getOmnivaLocations.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getOmnivaLocations.fulfilled, (state, { payload }) => {
          state.isLoading = false
          state.isSuccess = true
          state.omniva_locations = payload
        })
        .addCase(getOmnivaLocations.rejected, (state, { payload }) => {
          state.isError = true
          state.message = payload
        })
        .addCase(getSmartpostLocations.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getSmartpostLocations.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.smartpost_locations = action.payload;
        })
        .addCase(getSmartpostLocations.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
            state.smartpost_locations = [];
        })
    },
  })
  
  export const { reset } = shippingSlice.actions
  export default shippingSlice.reducer
  