import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import { getProductById, getProducts, reset } from '../../features/products/productSlice';
import { useDispatch } from 'react-redux';
import ButtonPrimary from '../../components/style-components/ButtonPrimary';
import {toast} from 'react-toastify';
import ProductList from '../../components/admin/ProductList';
import Spinner from '../../components/Spinner';
import { getOrders, getUnpaidOrders } from '../../features/orders/orderSlice';
import OrderList from '../../components/admin/OrderList';



function AdminOrders({unpaid = false}) {
    const { orders, labels, isLoading, isSuccess, isError, message } = useSelector((state) => state.order);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
      if (!user) {
        navigate('/');
      }
    }, [user, navigate]);

    useEffect(() => {
        if(isError) {
          toast.error(message)
        }

        return () => {
            dispatch(reset());
        };
      }, [orders, isLoading, isError, isSuccess, message]);
    
    
      useEffect(() => {
        unpaid ? dispatch(getUnpaidOrders()) : dispatch(getOrders())
      }, [unpaid]);

      if(!orders || isLoading) return <Spinner />;

  return (
    <div className='admin'>
        <OrderList orders={orders} labels={labels} unpaid={unpaid}/>
    </div>
  );
}

export default AdminOrders;