import React from 'react'
import ProductCard from './ProductCard';

function ProductGrid({products }) {
  return (
    <div id="produkti">
      {products.length === 0 && <p>...</p>}
      <div className="product-grid">
        {products.map(product => (
          <ProductCard key={product._id} product={product}/>
        ))}
      </div>
    </div>
  )
}

export default ProductGrid
