import React from 'react'
import ButtonPrimary from './style-components/ButtonPrimary'

function Cookies({setCookiesAccepted}) {
  return (
    <div className='cookies flex'>
        <div className="cookies-container flex column">
            <p className='padding-10-bottom'>
                Šajā vietnē mēs izmantojam sīkdatnes, lai nodrošinātu pareizu vietnes darbību, nodrošinātu “noraspora.lv” pakalpojumus un funkcijas, analizētu pārlūkošanas statistiku.
                <br /><br />
                Vairāk par to, ka mēs apstrādājam sīkdatnes un citu informāciju vari uzzināt mūsu <a href="/privatuma-politika" target='_blank'>privātuma politikā</a>.
            </p>
            <ButtonPrimary text='Piekrītu sīkdatnēm' type={"dark"} onClick={() => {
                localStorage.setItem('cookiesAccepted', 'true')
                setCookiesAccepted(true)
            }} />
        </div>
    </div>
  )
}

export default Cookies
