import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getSmartpostLocations, reset } from '../features/shipping/shippingSlice';
import { toast } from 'react-toastify';

function SmartpostSelect({ handleSelectPickup }) {
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);

  const { smartpost_locations, isLoading, isError, message } = useSelector((state) => state.shipping);

  const [searchTerm, setSearchTerm] = useState('');
  const [displayText, setDisplayText] = useState('');
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const [fullLocations, setFullLocations] = useState([]);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    if (smartpost_locations && smartpost_locations.length > 0) {      
      setSearchTerm('');
    }

    if(smartpost_locations) {
        const locations = smartpost_locations.map(location => {
            return {
                ...location,
                full_name: (location.publicName.en + ' ' + location.address.en.address + ' ' + location.postalCode + ' ' + location.address.en.postalCodeName).toLowerCase(),
            }
        });

        setFullLocations(locations);
    }

    return () => {
      dispatch(reset());
    };
  }, [smartpost_locations, isError, message, dispatch]);

  const handleValueChange = (e) => {
    const value = e.target.value;
    setDisplayText(value); 
    setSearchTerm(value.toLowerCase());
  };

  const filteredLocations = fullLocations.filter((location) => {
    return location.full_name.includes(searchTerm);
  });

  useEffect(() => {
    dispatch(getSmartpostLocations());
  }, [dispatch]);

  const handleFocus = () => {
    setIsDropdownVisible(true);
    setSearchTerm('');
  };

  const selectLocation = (location) => {
    handleSelectPickup(location)
    setIsDropdownVisible(false)
    setDisplayText(location.publicName.en)
  }

  const getName = (location) => {
    const city = location.address.en.municipality.charAt(0).toUpperCase() + location.address.en.municipality.slice(1).toLowerCase();
    return city + ', ' + location.publicName.en;
  }

  if(isLoading) {
    return <div>...</div>
  }

  return (
    <div className="flex column omniva-select padding-20-bottom">
      <input
        type="text"
        placeholder="Izvēlies sev tuvāko SmartPost lokāciju"
        value={displayText}
        onChange={handleValueChange}
        onFocus={handleFocus}
        className="padding-10"
      />
      {isDropdownVisible && (
        <div className="locations-dropdown" ref={dropdownRef}>
          {filteredLocations.map((location, index) => (
            <div
              key={index}
              onMouseDown={() => selectLocation(location)}
              className="location padding-10"
            >
              {getName(location)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SmartpostSelect;