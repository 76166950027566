
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import productService from './productService'
import { initiateStripe } from '../orders/orderSlice'
import { toast } from 'react-toastify'
import { removeDiscount  } from '../coupons/couponSlice' // Import the reset action

const cart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : []

const initialState = {
  products: [],
  discount: null,
  cart: cart,
  product: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Get products
export const getProducts = createAsyncThunk('products/all', async (thunkAPI) => {
  try {
    return await productService.getProducts()
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// Get products
export const getProductsAdmin = createAsyncThunk('products/admin', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await productService.getProductsAdmin(token)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// Get trashed products
export const getTrashedProducts = createAsyncThunk('products/trashed', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await productService.getTrashedProducts(token)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// Get product by id
export const getProductById = createAsyncThunk('products/single', async (id, thunkAPI) => {
  try {
    return await productService.getProductById(id)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// Get product by id
export const getProductByIdAdmin = createAsyncThunk('products/admin/single', async (id, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await productService.getProductByIdAdmin(token, id)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// Add product
export const addProduct = createAsyncThunk('products/add', async (product, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await productService.addProduct(token, product)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// Edit product
export const editProduct = createAsyncThunk('products/edit', async (product, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await productService.editProduct(token, product )
  } catch (error) {
    const message =

      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }}
);

// Delete product
export const deleteProduct = createAsyncThunk('products/delete', async (id, thunkAPI) => {
  
  try {
    const token = thunkAPI.getState().auth.user.token
    return await productService.deleteProduct(token, id)
  } catch (error) {
    const message =

      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }}
);

// Permanently delete product
export const permDeleteProduct = createAsyncThunk('products/permDelete', async (id, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await productService.permDeleteProduct(token, id)
  } catch (error) {
    const message =
    (error.response && error.response.data && error.response.data.message) ||
    error.message ||
    error.toString()
  return thunkAPI.rejectWithValue(message)
}}
);

// Renew product
export const renewProduct = createAsyncThunk('products/renew', async (id, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await productService.renewProduct(token, id)
  } catch (error) {
    const message =
    (error.response && error.response.data && error.response.data.message) ||
    error.message ||
    error.toString()
  return thunkAPI.rejectWithValue(message)
}}
);

// Validate coupon
export const validateCoupon = createAsyncThunk('products/validateCoupon', async (data, thunkAPI) => {
  try {
    return await productService.validateCoupon(data.code, data.productIds)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)  
  }
})

// Enter harvest
export const enterHarvest = createAsyncThunk('products/enterHarvest', async (data, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await productService.enterHarvest(token, data.product_id, data.variation_id, data.stock_addition)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)  
  }
})

// Enter stock
export const enterStock = createAsyncThunk('products/enterStock', async (data, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await productService.enterStock(token, data.product_id, data.variation_id, data.new_stock)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)  
  }
})

const checkStock = (product) => {
  const cart_array = JSON.parse(localStorage.getItem('cart')) || [];
  const cart = cart_array.map(item => JSON.parse(item));
  const products = JSON.parse(localStorage.getItem('products')) || [];

  const count_in_cart = cart.filter(item => item.variant.sku === product.variant.sku).length;

  const count_available = products.find(p => p._id === product._id).variants.find(v => v._id === product.variant._id).countInStock;

  return {
    available: count_in_cart < count_available,
    count: count_available,
  };
}

export const productSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
      reset: (state) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = false
        state.message = ''
      },
      addToCart: (state, action) => {
        const stock_check = checkStock(action.payload);
        if(stock_check.count < 1) {
          toast.error('Produkts šobrīd nav pieejams!');
          return;
        } else if (!stock_check.available) {
          toast.error(`Noliktavā pieejamais skaits: ${stock_check.count}`);
          return;
        }
        state.cart.push(JSON.stringify(action.payload));
        localStorage.setItem('cart', JSON.stringify(state.cart));
        removeDiscount();
        toast.success('Produkts pievienots grozam!');
      },
      restoreItems: (state, action) => {
        state.cart = action.payload.map(item => JSON.stringify(item));
        localStorage.setItem('cart', JSON.stringify(state.cart))
      },
      removeFromCart: (state, action) => {
        const newCart = state.cart.filter(item => {
            const cartItem = JSON.parse(item);
            if (cartItem.variant._id === action.payload.variant._id) {
                return false;
            }
            return true;
        });
    
        // Update the state
        state.cart = newCart;
    
        // Update the localStorage
        localStorage.setItem('cart', JSON.stringify(state.cart));
    },
    removeCoupon: (state) => {
      state.discount = null
      localStorage.removeItem('discount')
    },
      updateCart: (state, action) => {
        state.cart = action.payload;
        localStorage.setItem('cart', JSON.stringify(state.cart));
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(getProducts.pending, (state) => {
          state.products = []
          state.isLoading = true
        })
        .addCase(getProducts.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.products = action.payload
          localStorage.setItem('products', JSON.stringify(action.payload))
        })
        .addCase(getProducts.rejected, (state, action) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
          state.products = []
        })
        .addCase(getProductById.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getProductById.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.product = action.payload
        })
        .addCase(getProductById.rejected, (state, action) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
          state.product = null
        })
        .addCase(addProduct.pending, (state) => {
          state.isLoading = true
        })
        .addCase(addProduct.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.products = [...state.products, action.payload]
          toast.success('Produkts pievienots!')
          window.location.href = '/edit-products/'
        })
        .addCase(addProduct.rejected, (state, action) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
          state.product = null
        })
        .addCase(editProduct.pending, (state) => {
          state.isLoading = true
          state.product = null
        })
        .addCase(editProduct.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
          toast.success('Produkts saglabāts!');
          
          const updatedProduct = action.payload;
          state.products = state.products.map(product =>
            product.id === updatedProduct.id ? updatedProduct : product
          );
          // window.location.href = '/edit-products/'
        })
        .addCase(editProduct.rejected, (state, action) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
          state.product = null
        })
        .addCase(deleteProduct.pending, (state) => {
          state.isLoading = true
        })
        .addCase(deleteProduct.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.products = state.products.filter(product => product._id !== action.payload._id)
        })
        .addCase(deleteProduct.rejected, (state, action) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
          state.products = null
        })
        .addCase(initiateStripe.fulfilled, (state, action) => {
          state.cart = []
          localStorage.removeItem('discount')
          window.location.href = action.payload.url
        })
        .addCase(getProductsAdmin.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getProductsAdmin.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.products = action.payload
        })
        .addCase(getProductsAdmin.rejected, (state, action) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
          state.products = []

        })
        .addCase(validateCoupon.pending, (state) => {
          state.isLoading = true
        })
        .addCase(validateCoupon.fulfilled, (state, action) => {
          state.isSuccess = true
          state.discount = action.payload
          localStorage.setItem('discount', JSON.stringify(action.payload))
          state.isLoading = false
        })
        .addCase(validateCoupon.rejected, (state, action) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
        .addCase(getTrashedProducts.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getTrashedProducts.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.products = action.payload
        })
        .addCase(getTrashedProducts.rejected, (state, action) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
          state.products = []
        })
        .addCase(permDeleteProduct.pending, (state) => {
          state.isLoading = true
        })
        .addCase(permDeleteProduct.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.products = state.products.filter(product => product._id !== action.payload._id)
        })
        .addCase(permDeleteProduct.rejected, (state, action) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
          state.products = []
        })
        .addCase(renewProduct.pending, (state) => {
          state.isLoading = true
        })
        .addCase(renewProduct.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.products = []
        })
        .addCase(renewProduct.rejected, (state, action) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
          state.products = []
        })
        .addCase(enterHarvest.pending, (state) => {
          state.isLoading = true
        })
        .addCase(enterHarvest.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          const index = state.products.findIndex(product => product._id === action.payload._id);
          if (index !== -1) {
            state.products[index] = action.payload;
          }
        })
        .addCase(enterHarvest.rejected, (state, action) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
        .addCase(enterStock.pending, (state) => {
          state.isLoading = true
        })
        .addCase(enterStock.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          const index = state.products.findIndex(product => product._id === action.payload._id);
          if (index !== -1) {
            state.products[index] = action.payload;
          }
          window.location.reload();
        })
        .addCase(enterStock.rejected, (state, action) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
        .addCase(getProductByIdAdmin.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getProductByIdAdmin.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.product = action.payload
        })
        .addCase(getProductByIdAdmin.rejected, (state, action) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
          state.product = null
        })
    },
  })
  
  export const { reset, addToCart, removeFromCart, updateCart, removeCoupon, restoreItems } = productSlice.actions
  export default productSlice.reducer
  
