import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getOrder, toggleStatus, reset } from '../../features/orders/orderSlice';
import Spinner from '../../components/Spinner';
import ButtonPrimary from '../../components/style-components/ButtonPrimary';
import Confirmation from '../../components/Confirmation';
import { FaArrowLeft } from "react-icons/fa";
import { CiRedo } from "react-icons/ci";
import { TbFileInvoice } from "react-icons/tb";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { MdOutlineLocalShipping, MdOutlineCancel, MdMoneyOff, MdOutlineAttachMoney } from "react-icons/md";
import { FiRewind } from "react-icons/fi";

function AdminEditOrder() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const { order, isLoading, isError, message } = useSelector((state) => state.order);
    const { orderId } = useParams();

    const [orderObject, setOrderObject] = useState(null)

    const [displayConfirm, setDisplayConfirm] = useState(false);
    const [actionType, setActionType] = useState('');

    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
      if (!user) {
        navigate('/');
      }
    }, [user, navigate]);

    const filterObject = (obj, keysToExclude) => {
        return Object.keys(obj)
          .filter(key => !keysToExclude.includes(key))
          .reduce((result, key) => {
            result[key] = obj[key];
            return result;
          }, {});
      };

    useEffect(() => {
        if (isError) {
            toast.error(message);
        }

        if(order){
            const filteredJsonObject = filterObject(order, ['invoice_label', 'shipping_label']);
            setOrderObject(filteredJsonObject)
        }

        return () => {
            dispatch(reset());
        };
    }, [order, isLoading, isError, message, dispatch]);

    useEffect(() => {
        dispatch(getOrder(orderId));
    }, [dispatch, orderId]);

    const handleActionClick = (type) => {
        setActionType(type);
        setDisplayConfirm(true);
    };

    const handleClickShipped = () => {
        handleActionClick('shipped');
    };

    const handleClickProcessing = () => {
        handleActionClick('processing');
    };

    const handleClickCancelled = () => {
        handleActionClick('cancelled');
    };

    const handleClickNew = () => {
        handleActionClick('new');
    };

    const confirmAction = () => {
        dispatch(toggleStatus({ids: [order._id], status: actionType}));
        setDisplayConfirm(false);
    };

    const getStatusString = (status) => {
        switch (status) {
            case 'unpaid': return 'Neapmaksāts';
            case 'new': return 'Jauns pasūtījums';
            case 'processing': return 'Apstrādē';
            case 'shipped': return 'Nosūtīts';
            case 'cancelled': return 'Atcelts';
            default: return '';
        }
    };

    const downloadShipping = (fileName = 'document.pdf') => {
        if(!order.shipping_label && !order.smartpost_tracking){
            toast.error('Pasūtījumam nav izveidots sūtījums');
            return;
        }

        const data = order.shipping_label.data;

        // Convert byte array to Uint8Array
        const byteArray = new Uint8Array(data);

        // Create a Blob with PDF MIME type
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element and trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); // Set the file name
        document.body.appendChild(link);
        link.click(); // Trigger the download
        link.parentNode.removeChild(link); // Clean up
        window.URL.revokeObjectURL(url); // Clean up the URL object
    };

    const downloadInvoice = (fileName = 'document.pdf') => {
        if(!order.invoice_label){
            toast.error('Kaut kas nogāja greizi');
            return;
        }

        const data = order.invoice_label.data;

        // Convert byte array to Uint8Array
        const byteArray = new Uint8Array(data);

        // Create a Blob with PDF MIME type
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element and trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); // Set the file name
        document.body.appendChild(link);
        link.click(); // Trigger the download
        link.parentNode.removeChild(link); // Clean up
        window.URL.revokeObjectURL(url); // Clean up the URL object
    };

    const formatPrice = (price) => {
        return (price / 100).toFixed(2) + '€';
    };

    if (isLoading || !order) {
        return <Spinner />;
    }


    console.log(order)

    return (
        <div className="admin-order-details padding-20-bottom">
            <ButtonPrimary onClick={() => navigate('/edit-orders')}><FaArrowLeft style={{marginBottom: "-2px", marginRight: "10px"}}/>Atpakaļ</ButtonPrimary>
            <div className="order-controls flex gap-10 space-between">
                <div className="left flex column gap-10">
                    <div className="flex column gap-10">
                        <h5 className="light">Pasūtījuma statuss:</h5>
                        <div className="flex gap-10">
                            <ButtonPrimary onClick={handleClickProcessing}><CiRedo size={15} style={{marginBottom: "-3px", paddingRight: "10px"}}/> Apstrādē</ButtonPrimary>
                            <ButtonPrimary onClick={handleClickShipped}><MdOutlineLocalShipping size={15} style={{marginBottom: "-3px", paddingRight: "10px"}}/>Izsūtīts</ButtonPrimary>
                        </div>
                        <div className="flex gap-10">
                            <ButtonPrimary onClick={handleClickNew}><FiRewind size={15} style={{marginBottom: "-3px", paddingRight: "10px"}}/>Jauns pasūtījums</ButtonPrimary>
                            <ButtonPrimary onClick={handleClickCancelled}><MdOutlineCancel size={15} style={{marginBottom: "-3px", paddingRight: "10px"}}/>Atcelts</ButtonPrimary>
                        </div>
                    </div>

                    <div className="flex column gap-10 padding-20-top">
                        <h5 className="light">Pasūtījuma lejupielādes:</h5>
                        <div className="flex gap-10">
                        <ButtonPrimary onClick={() => downloadShipping("Ceļa Karte " + order.client.firstName + " " + order.client.lastName)}><IoCloudDownloadOutline size={15} style={{marginBottom: "-3px", paddingRight: "10px"}}/>Lejupielādēt ceļa karti</ButtonPrimary>
                        <ButtonPrimary onClick={() => downloadInvoice("Rēķins " + order.client.firstName + " " + order.client.lastName)}><TbFileInvoice size={15} style={{marginBottom: "-3px", paddingRight: "10px"}}/>Lejupielādēt rēķinu</ButtonPrimary>
                    </div>
                    </div>
                    
                    
                </div>
                
                <div className="right">
                    <div>
                        <h5 className="light padding-10-bottom">Pasūtījuma kopsavilkums:</h5>
                        <div className="admin-order-summary">
                            <p>ID: {order._id}</p>
                            <p>Numurs: #{order.order_num}</p>
                            <p>E-pasta status: {order.new_order_email_sent ? "veiksmīgi nosūtīts" : "kļūda"}</p>
                            <p>Piegādes veids: {order.shipping_method === 'smartpost' ? 'SmartPost (Posti)' : order.shipping_method === 'omniva' ? 'Omniva' : 'Nav norādīta.... (rakstīt Aleksim)'}</p>
                            {order.shipping_method === 'omniva' && <p>Omniva sūtījuma kods: {order.omniva_label ? order.omniva_label : "kļūda"}</p>}
                            {order.shipping_method === 'smartpost' && <p>SmartPost sūtījuma kods: {order.smartpost_tracking ? order.smartpost_tracking : "kļūda"}</p>}
                            <p>Summa: {formatPrice(order.totalPrice)}</p>
                            <p>Atlaide: {formatPrice(order.totalPrice - order.payment.amount)}</p>
                            <p>Samaksāts: {formatPrice(order.payment.amount)}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="order-info flex padding-20-top">
                <div className="flex gap-10">
                    <div className="order-box payment-info">
                        <p className="bold">Pasūtījums</p>
                        <p>Statuss: {getStatusString(order.status)}</p>
                        <p>Datums: {order.createdAt.split('T')[0]}</p>
                        <p>Summa: {formatPrice(order.payment.amount)}</p>
                    </div>
                    <div className="order-box payment-info">
                        <p className='bold'>Maksātājs</p>
                        <p>Vārds: {order.client.firstName} {order.client.lastName}</p>
                        <p>E-pasts: {order.client.email}</p>
                        <p>Telefons: {order.client.phone}</p>
                        <p>Adrese: {order.client.street}, {order.client.city}, LV-{order.client.postcode}</p>
                    </div>
                </div>
                <div className="flex gap-10">
                    <div className="order-box payment-info">
                        <p className='bold'>Maksājums</p>
                        <p>Pasūtījuma summa: {formatPrice(order.totalPrice)}</p>
                        <p>Produktu summa: {formatPrice(order.itemPrice)}</p>
                        {order.shippingZip ? <p>Piegādes cena: {formatPrice(order.shippingPrice)}</p> : <p>Piegāde: bezmaksas</p>}
                        {order.coupon && <p>Atlaižu kods: {order.coupon.code}</p>}
                        {order.coupon ? order.coupon.discountAmount ? <p>Atlaide: {formatPrice(order.coupon.discountAmount)}</p> : null : null}
                        {order.coupon ? order.coupon.discountPercentage ? <p>Atlaide: {order.coupon.discountPercentage}%</p> : null : null}
                        {order.coupon ? order.coupon.delivery ? <p>Piegādes atlaide: {formatPrice(order.shippingPrice)}</p> : null : null}
                    </div>
                    <div className="order-box payment-info">
                        <p className='bold'>Saturs</p>
                        <ul>
                        {order.orderItems.map((item) => (
                            <li key={item._id}>
                                {item.quantity}x {item.name} ({item.variant_name}) - {formatPrice(item.price * item.quantity)}
                            </li>
                        ))}
                        </ul>
                    </div>
                </div>
            </div>
            <pre>{JSON.stringify(orderObject, null, 2)}</pre>

            {/* Confirmation Dialog */}
            {displayConfirm && (
                <Confirmation
                    message={`Vai esi pārliecināts, ka vēlies atzīmēt pasūtījumu kā '${actionType}'?`}
                    onConfirm={confirmAction}
                    onCancel={() => setDisplayConfirm(false)}
                />
            )}
        </div>
    );
}

export default AdminEditOrder;



// <div className="quick-order-info">
//                 {order.shippingZip ? <p style={{fontSize: "1.4rem"}}>Omniva kods: {order.shippingZip}</p> : <p style={{fontSize: "1.4rem"}}>Saņemšana Tērbatas 56</p>}
//             </div>
//             {displayConfirm && (
//                 <Confirmation
//                     message={`Vai esi pārliecināts, ka vēlies atzīmēt pasūtījumu kā '${actionType}'?`}
//                     onConfirm={confirmAction}
//                     onCancel={() => setDisplayConfirm(false)}
//                 />
//             )}
//             <div className="flex">
//                 <p></p>
//             </div>
//             <div className="payment-client-info">
//                 <div className="order-box payment-info">
//                     <h4 className='light'>Pasūtījuma informācija</h4>
//                     <p>ID: {order._id}</p>
//                     <p>Statuss: {getStatusString(order.status)}</p>
//                     <p>Datums: {order.createdAt.split('T')[0]}</p>
//                     <p>Summa: {order.payment.amount}€</p>
//                     <p>Piegāde: {order.shippingZip ? "Omniva" : "Saņemšana Tērbatas 56"}</p>
//                 </div>
//                 <div className="order-box client-info">
//                     <h4 className='light'>Pircēja informācija</h4>
//                     <p>Vārds: {order.client.firstName} {order.client.lastName}</p>
//                     <p>E-pasts: {order.client.email}</p>
//                     <p>Telefons: {order.client.phone}</p>
//                     <p>Adrese: {order.client.street}, {order.client.city}, {order.client.postcode}</p>
//                 </div>
//             </div>
//             <div className="order-info">
//                 <div className="order-box payment-info">
//                     <h4 className='light'>Maksājuma informācija</h4>
//                     <p>Pasūtījuma summa: {order.totalPrice}€</p>
//                     <p>Produktu summa: {order.itemPrice}€</p>
//                     {order.shippingZip ? <p>Piegādes cena: {order.shippingPrice} €</p> : <p>Piegāde: bezmaksas</p>}
//                     {order.coupon && <p>Atlaižu kods: {order.coupon.code}</p>}
//                     {order.coupon ? order.coupon.discountAmount ? <p>Atlaide: {order.coupon.discountAmount}€</p> : null : null}
//                     {order.coupon ? order.coupon.discountPercentage ? <p>Atlaide: {order.coupon.discountPercentage}%</p> : null : null}
//                     {order.coupon ? order.coupon.delivery ? <p>Piegādes atlaide: {order.shippingPrice}€</p> : null : null}
                    
//                     <p>Kopā apmaksāts: {order.payment.amount}€</p>
//                     <p>Maksājuma metode: {order.payment.method}</p>
//                     <p>Maksājuma statuss: {order.payment.status}</p>
//                     <p>Maksājuma ID: {order.payment.transactionId}</p>
//                 </div>
//                 <div className="order-box product-info">
//                     <h4 className='light'>Pasūtījuma saturs</h4>
//                     {order.orderItems.map((item) => (
//                         <div className="product-item" key={item._id}>
//                             <div className="order-product-details padding-10-left">
//                                 <p>{item.quantity}x</p>
//                                 <p>{item.product.title}</p>
//                                 <p>{item.price * item.quantity}€</p>
//                             </div>
//                         </div>
//                     ))}
//                 <p className='padding-10-left'>Kopā: {order.orderItems.reduce((total, item) => total + (item.price * item.quantity), 0)}€</p>
//                 </div>

//             </div>
