import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../../img/ns_logo.png';
import logo_light from '../../img/logo_light.png';
import CartCounter from '../style-components/CartCounter';
import MenuItem from '../style-components/MenuItem';
import { Cross as Hamburger } from 'hamburger-react';
import { animateScroll as scroll, scroller } from 'react-scroll';
 

function ClientMobile({isHome}) {
    const [isOpen, setOpen] = useState(false);
    const [isSubmenuOpen, setSubmenuOpen] = useState(false);

    const [scrollToVeikals, setScrollToVeikals] = useState(false);
  
    const navigate = useNavigate();
    const location = useLocation();
  
    useEffect(() => {
        setOpen(false);
        setSubmenuOpen(false);
    }, [location.pathname]);

    
    const toggleSubmenu = () => {
      setSubmenuOpen(!isSubmenuOpen);
    };
  
    useEffect(() => {
      setSubmenuOpen(false);
    }, [location.pathname]);
  
    useEffect(() => {
      if (scrollToVeikals && isHome) {
        scroller.scrollTo('veikals', {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
          offset: -100, // Scrolls to 100px above the element
        });
        setScrollToVeikals(false);
      }
    }, [scrollToVeikals, isHome]);
  
    const handleVeikalsClick = (e) => {
      setOpen(false);
      e.preventDefault();
      if (location.pathname !== '/') {
        setScrollToVeikals(true);
        navigate('/');
      } else {
        scroller.scrollTo('veikals', {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
          offset: -100, // Scrolls to 100px above the element
        });
      }
    };
  
    const handleLogoClick = () => {
      if (isHome) {
        scroll.scrollToTop({
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
        });
      } else {
        navigate('/');
      }
    };
  
    useEffect(() => {
      if (location.hash === '#veikals') {
        scroller.scrollTo('veikals', {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
          offset: -100, // Scrolls to 100px above the element
        });
      }
    }, [location]);

    return (
        <>
            <div className={`mobile-header ${isHome ? "light" : ""}`}>
                <div className="menu-trigger">
                    <Hamburger className="menu-trigger-icon" size={25} direction="right" toggled={isOpen} toggle={setOpen} />
                </div>
                <div className="logo" onClick={handleLogoClick}>
                    {isHome ? <img src={logo_light} alt="NoraSpora" />:<img src={logo} alt="NoraSpora" />}
                </div>
                <div className="header-cart">
                    <CartCounter />
                </div>
            </div>
            {isOpen &&
                <div className={`mobile-menu ${isHome ? "light" : ""}`}>
                  <div className="menu-item-with-submenu">
                    <MenuItem text="Veikals" onClick={handleVeikalsClick} />
                  </div>
                  <MenuItem text="Instrukcija" link="/instrukcija" />
                  <MenuItem text='Jautājumi' link='/jautajumi' />
                  <MenuItem text='Par mums' link="/about" />
                  <MenuItem text='Kontakti' link="/contacts" />
                  {/* Galveno pogu secība: Veikals, Instrukcija, Jautājumi, Par mums, Kontakti */}
                </div>
            }
        </>
    );
}

export default ClientMobile;


{/* {isSubmenuOpen &&
    <div className="padding-10-top">
        <div className="submenu">
            <MenuItem text='Audzēšanas Bloki' link="/shop/audzesanas-bloki" />
            <MenuItem text='Specpasūtījumi' link="/shop/specpasutijumi" />
        </div>
    </div>
    
} */}