import SmartpostSelect from './SmartpostSelect';
import OmnivaSelect from './OmnivaSelect';

function ShippingSelection({ formData, setFormData, selectedShippingMethod, setSelectedShippingMethod, setSelectedPickupObject, setSelectedShippingZip }) {

    const handleSelectPickup = (pickup) => {
        setSelectedPickupObject(pickup);

        switch(selectedShippingMethod) {
            case 'smartpost':
                setSelectedShippingZip(pickup.postalCode);
                setSelectedPickupObject(getShippingObject(pickup));
                break;
            case 'omniva':
                setSelectedShippingZip(pickup.ZIP[0]);
                setSelectedPickupObject(getShippingObject(pickup));
                break;
            default:
                setSelectedShippingZip('');
                break;
        }
    }

    const getShippingObject = (pickup) => {
        switch(selectedShippingMethod) {
            case 'smartpost':
                setFormData({
                  ...formData,
                  'shippingAddress': pickup.address.en.streetName + ' ' + pickup.address.en.streetNumber,
                  'shippingCity': pickup.address.en.municipality,
                  'shippingPostcode': pickup.address.en.postalCode
                });

                return {
                    city: pickup.address.en.municipality,
                    street: pickup.address.en.streetName,
                    house_no: pickup.address.en.streetNumber,
                    zip: pickup.address.en.postalCode,
                    name: pickup.publicName.en,
                    smartpost_pup_code: pickup.pupCode,
                }
            case 'omniva':
                setFormData({
                  ...formData,
                  'shippingAddress': pickup.A5_NAME[0] + ' ' + pickup.A7_NAME[0],
                  'shippingCity': pickup.A1_NAME[0],
                  'shippingPostcode': pickup.ZIP[0]
                });

                return {
                    city: pickup.A1_NAME[0],
                    street: pickup.A5_NAME[0],
                    house_no: pickup.A7_NAME[0],
                    zip: pickup.ZIP[0],
                    name: pickup.NAME[0]
                }
            default:
                return {
                    city: '',
                    street: '',
                    house_no: '',
                    zip: '',
                    name: ''
                }
        }
    }

  return (
    <div className="checkout-shipping padding-20-top">
          <h4>Piegādes veids:</h4>
          <div className='padding-10-top'>
            <input 
              type="radio" 
              id="omniva" 
              name="delivery" 
              value="omniva" 
              disabled
              checked={selectedShippingMethod === 'omniva' ? true : false} 
              onChange={() => {
                setSelectedShippingMethod('omniva');
              }}
            />
            <label htmlFor="omniva">Omniva <span className='small'>*Nav pieejams ziemas mēnešos</span></label>
          </div>
          <div className='padding-10-top'>
            <input 
              type="radio" 
              id="omniva" 
              name="delivery" 
              value="omniva" 
              disabled
              checked={selectedShippingMethod === 'latvijas-pasts' ? true : false} 
              onChange={() => {
                setSelectedShippingMethod('latvijas-pasts');
              }}
            />
            <label htmlFor="omniva">Latvijas pasts <span className='small'>*Tikai starptautiskiem sūtījumiem (drīzumā)</span></label>
          </div>
          <div className='padding-10-top'>
            <input 
              type="radio" 
              id="pickup" 
              name="delivery" 
              value="pickup" 
              checked={selectedShippingMethod === 'smartpost' ? true : false} 
              onChange={() => {
                setSelectedShippingMethod('smartpost');
              }}
            />
            <label htmlFor="pickup">Smartpost</label>
          </div>

          <div className="padding-10-top"></div>
          {selectedShippingMethod === 'omniva' && <OmnivaSelect handleSelectPickup={handleSelectPickup}  />}
          {selectedShippingMethod === 'smartpost' && <SmartpostSelect handleSelectPickup={handleSelectPickup} />}
        </div>
  )
}

export default ShippingSelection
