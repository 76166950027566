import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTrashedProducts, getProductsAdmin, reset, enterHarvest } from '../../features/products/productSlice';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ProductList from '../../components/admin/ProductList';
import Spinner from '../../components/Spinner';
import ButtonPrimary from '../../components/style-components/ButtonPrimary';

function AdminHarvest() {
    const { products, product, isLoading, isError, message } = useSelector((state) => state.product);

    const [allVariations, setAllVariations] = useState([]);
    const [stockChange, setStockChange] = useState({});

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
      if (!user) {
        navigate('/');
      }
    }, [user, navigate]);

    useEffect(() => {
        if (isError) {
            toast.error(message);
        }

        return () => {
            dispatch(reset());
        };
    }, [isError, message, dispatch]);

    useEffect(() => {
        dispatch(getProductsAdmin());
    }, [dispatch]);

    useEffect(() => {
        if (products) {
            const variations = products.flatMap((product) => 
                product.variants.map((variant, index) => ({
                    product_id: product._id,
                    variation_name: variant.variant_name,
                    variation_id: variant._id,
                    product_name: product.title,
                    variation_stock: variant.countInStock,
                    index,
                }))
            );
            setAllVariations(variations);
        }
    }, [products]);

    const handleStockChange = (product_id, variation_id, stock_addition) => {
        const parsedStockAddition = parseInt(stock_addition, 10);
        if (!isNaN(parsedStockAddition)) {
            dispatch(enterHarvest({product_id, variation_id, stock_addition: parsedStockAddition}));
            setStockChange(prevState => ({ ...prevState, [variation_id]: '' }));
        } else {
            toast.error("Invalid stock addition value");
        }
    }

    const handleInputChange = (variation_id, value) => {
        setStockChange(prevState => ({ ...prevState, [variation_id]: value }));
    }

    if (isLoading || !products || products.length === 0) return <Spinner />;

    return (
        <div className='variations padding-20-bottom'>
            <h2 className="light">Ievadīt ražas daudzumu</h2>
            {allVariations.map((variation) => (
                <div key={variation.index} className="variation">
                    <div className="variation-info">
                        <p>{variation.product_name} ({variation.variation_name})</p>
                    </div>
                    <div className="product-stock-management">
                        <p>{variation.variation_stock}</p>
                        <input
                            type="text"
                            className="basic-input"
                            placeholder='Ražas daudzums'
                            value={stockChange[variation.variation_id] || ''}
                            onChange={(e) => handleInputChange(variation.variation_id, e.target.value)}
                        />
                        <ButtonPrimary text="Saglabāt" onClick={() => handleStockChange(variation.product_id, variation.variation_id, stockChange[variation.variation_id])}/>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default AdminHarvest;
