import axios from 'axios'

const API_URL = '/users/'


// Login user
const login = async (userData) => {
  const response = await axios.post(API_URL + 'login', userData)

  if (response.data) {
    const expiryTime = new Date().getTime() + 30 * 60 * 1000; // 30 minutes from now
    localStorage.setItem('user', JSON.stringify({ ...response.data, expiryTime }))
  }

  return response.data
}

// Logout user
const logout = () => {
  localStorage.removeItem('user')
}

const authService = {
  logout,
  login,
}

export default authService