import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Spinner from "../components/Spinner";
import { useDispatch, useSelector } from 'react-redux';
import { getOrderPublic } from "../features/orders/orderSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Processing() {
    const { orderId } = useParams();
    const order = useSelector(state => state.order.order);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [refreshCount, setRefreshCount] = useState(0);

    useEffect(() => {
        // Function to fetch order status
        const fetchOrderStatus = async () => {
            try {
                await dispatch(getOrderPublic(orderId)); // Ensure dispatch is awaited if it's a promise
                setRefreshCount(prevCount => prevCount + 1); // Increment the refresh count based on the previous state
            } catch (error) {
                console.error('Error fetching order status:', error);
            }
        };

        // Fetch order status every second
        const intervalId = setInterval(fetchOrderStatus, 1000);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, [dispatch, orderId]);

    useEffect(() => {
        if (order && order.status === 'new') {
            window.location.href = `/order-confirmation/${order._id}`;
            localStorage.removeItem('cart');
        }
    }, [order]);

    useEffect(() => {
        if(order && refreshCount > 10) {
            toast.error('Kaut kas nogāja greizi. Lūdzu mēģiniet vēlreiz.');
            navigate('/');
        }
    }, [navigate, order, refreshCount]);

    return (
        <div className="processing">
            <Spinner />
        </div>
    );
}

export default Processing;
