import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import bloka_pirmais from '../img/bloka_pirmais.png';
import bloka_otrais from '../img/bloka_otrais.png';
import bloka_tresais from '../img/bloka_tresais.png';
import bloka_ceturtais from '../img/bloka_ceturtais.png';
import thumbnail from '../img/thumbnail.jpeg';
import { Helmet } from 'react-helmet';
import { getTexts } from '../features/texts/textSlice';
import Spinner from '../components/Spinner';

const Instrukcija = () => {
    const dispatch = useDispatch();

    const { texts, isLoading, isError, isSuccess, message } = useSelector((state) => state.texts);

    const [mobile, setMobile] = useState(window.innerWidth < 700);

    useEffect(() => {
        dispatch(getTexts());
    }, []);

    // useEffect(() => {
    //     if(texts){
    //         console.log(texts);
    //     }
    // }, [texts]);

    useEffect(() => {
      const handleResize = () => {
        setMobile(window.innerWidth < 1100);
      };

      // Attach the event listener
      window.addEventListener('resize', handleResize);

      // Cleanup function
      return () => {
        // Remove the event listener
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const getValueByTag = (tag) => {
        const textObject = texts.find(text => text.tag === tag);
        return textObject ? { __html: textObject.value } : { __html: '' };
      }

    if(isLoading){
        return <Spinner />
    }

    return (
        <div className="instrukcija-page">
            <Helmet>
            <title>Nora Spora - Instrukcija</title>
            
            <meta name="description" content="Atklāj savu iekšējo mikologu kopā ar Nora Spora! Lejupielādē instrukciju un skatīties video instrukciju par sēņu audzēšanu mājās." />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>

            <h2 className="light">Atklāj savu iekšējo mikologu kopā ar Nora Spora!</h2>
                <div className="flex gap-10 padding-10-top">
                {/* <ButtonPrimary text="Lejupielādēt Instrukciju" onClick={() => {
                    const link = document.createElement('a');
                    link.href = tutorialpdf;
                    link.download = 'instrukcija.pdf';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }} /> */}
                {/* <ButtonPrimary text="Video Instrukcija" onClick={() => {
                    window.open('https://youtube.com/shorts/Q8KX1xwKVak?feature=share', '_blank');
                }} /> */}
                

            </div>

            {/* Instrukcija intro */}
            <div className="new-option" dangerouslySetInnerHTML={getValueByTag('instrukcija-intro')}></div>
            
            <div className="padding-20-top">
            <img className='video-thumbnail' src={thumbnail} alt="instrukcija" onClick={() => {
            window.open('https://youtube.com/shorts/Q8KX1xwKVak?feature=share', '_blank');
        }}/>
            </div>

            <h3 className='padding-40-top'>Sēņu audzēšanas process</h3>
            {/* Audzēšanas process */}
            <div className="new-option padding-20-top" dangerouslySetInnerHTML={getValueByTag('instrukcija-audzesanas-process')}></div>
    
            {/* Audzēšanas process BILDES */} 
            <div className="new-option">
                <div className="images padding-20-top">
                    <div className="bloka-image">
                        <img src={bloka_pirmais} alt="" />
                    </div>
                    <div className="bloka-image">
                        <img src={bloka_otrais} alt="" />
                    </div>
                    <div className="bloka-image">
                        <img src={bloka_tresais} alt="" />
                    </div>
                    <div className="bloka-image">
                        <img src={bloka_ceturtais} alt="" />
                    </div>
                </div>
            </div>

            <div className="instrukcijas-teksti padding-20-bottom" >
                <div className="instrukcijas-teksts padding-20-top">
                    <h3 className='padding-20-top'>Kur audzēt?</h3>
                    <div className="instrukcija-content">
                        <div className="instrukcija-content-text padding-20-top" dangerouslySetInnerHTML={getValueByTag('instrukcija-kur-audzet')}></div>
                    </div>
                    
                </div>
            

                <div className="instrukcijas-teksts padding-20-top">
                <h3 className='padding-20-top'>Kāda temperatūra ir vēlama?</h3>
                    <div className="instrukcija-content">
                        {mobile ? <div className="instrukcija-content-text padding-20-top" dangerouslySetInnerHTML={getValueByTag('instrukcija-kada-temperatura-mobile')}></div> : <div className="instrukcija-content-text padding-20-top" dangerouslySetInnerHTML={getValueByTag('instrukcija-kada-temperatura')}></div>}
                        
                    </div>
                    
                </div>

                <div className="instrukcijas-teksts padding-20-top">
                    <div className="instrukcija-content-full">
                        <h3 className='padding-20-top'>Kad sēnes būs gatavas?</h3>
                        <div className="padding-20-top" dangerouslySetInnerHTML={getValueByTag('instrukcija-kad-gatavas')}></div>
                    </div>
                </div>

                <div className="instrukcijas-teksts padding-20-top">
                    <div className="instrukcija-content-full">
                        <h3 className='padding-20-top'>Kā novākt ražu?</h3>
                        <div className="padding-20-top" dangerouslySetInnerHTML={getValueByTag('instrukcija-ka-novakt')}></div>
                    </div>
                </div>

                <div className="instrukcijas-teksts padding-20-top">
                    <div className="instrukcija-content-full">
                        <h3 className='padding-20-top'>Kā tās gatavot?</h3>
                        <div className="padding-20-top" dangerouslySetInnerHTML={getValueByTag('instrukcija-ka-gatavot')}></div>
                    </div>
                </div>

                <div className="instrukcijas-teksts padding-20-top">
                    <h3 className='padding-20-top'>Vai ir dzīve pēc ražas?</h3>
                    <div className="instrukcija-content">
                        
                        <div className="instrukcija-content-text">
                            <div className="padding-20-top" dangerouslySetInnerHTML={getValueByTag('instrukcija-pec-razas')}></div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        
    );
}

export default Instrukcija;