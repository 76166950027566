import axios from 'axios'

const API_URL = '/products/'

// Get products
const getProducts = async () => {
    const response = await axios.get(API_URL)
    return response.data
}

// Get products admin
const getProductsAdmin = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    
    const response = await axios.get(API_URL + 'admin-all', config)
    return response.data;
}

// Get trashed
const getTrashedProducts = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await axios.get(API_URL + 'admin-trashed', config)
    return response.data;
}

// Get product by id
const getProductById = async (id) => {
    const response = await axios.get(API_URL + id)
    return response.data
}

// Get product by id admin
const getProductByIdAdmin = async (token, id) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await axios.get(API_URL + "admin/" + id, config)
    return response.data
}

// Add product
const addProduct = async (token, product) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await axios.post(API_URL, product, config)
    return response.data
}

// Edit product
const editProduct = async (token, product) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await axios.put(API_URL + product._id, product, config)
    return response.data
}

// Delete product
const deleteProduct = async (token, id) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await axios.delete(API_URL + id, config)
    return response.data
}

// Permanently delete product
const permDeleteProduct = async (token, id) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await axios.delete(API_URL + 'perm/' + id, config)
    return response.data
}

const validateCoupon = async (code, productIds) => {
    const response = await axios.post('/coupons/validate', { code, productIds })
    return response.data
}

// Renew a product
const renewProduct = async (token, id) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await axios.post(API_URL + id, {}, config)
    return response.data
}

// Enter harvest
const enterHarvest = async (token, product_id, variation_id, stock_addition) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await axios.post(API_URL + 'enter-harvest', { product_id, variation_id, stock_addition }, config)
    return response.data
}

// Enter harvest
const enterStock = async (token, product_id, variation_id, new_stock) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await axios.post(API_URL + 'enter-stock', { product_id, variation_id, new_stock }, config)
    return response.data
}



const productService = {
    getProducts,
    addProduct,
    getProductById,
    editProduct,
    deleteProduct,
    getProductsAdmin,
    validateCoupon,
    getTrashedProducts,
    permDeleteProduct,
    renewProduct,
    enterHarvest,
    getProductByIdAdmin,
    enterStock
}
  
export default productService
