import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ButtonPrimary from '../components/style-components/ButtonPrimary';
import ProductGrid from '../components/ProductGrid';
import {getProducts, reset } from '../features/products/productSlice';
import Spinner from '../components/Spinner';
import videoFile from '../img/video.mp4'; // Adjust the path as necessary
import { animateScroll as scroller } from 'react-scroll';
import { Helmet } from 'react-helmet';

function Home() {
  const dispatch = useDispatch();

  const { products, isLoading, isError, message } = useSelector((state) => state.product);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [products, isError, message, dispatch]);

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  const handleVeikalsClick = (e) => {
    e.preventDefault();
    
    scroller.scrollTo('veikals', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -100, // Scrolls to 100px above the element
    });
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="home-page">
      <Helmet>
        <title>Nora Spora - Sēņu Audzēšanas Komplekti</title>
        <meta name="description" content="Nora Spora piedāvā plašu klāstu sēņu audzēšanas komplektu, tostarp rozā un pelēkās austersēnes, ežu dižadatenes, gļotainās zvīņenes un drīzumā arī citas." />
        <meta name="keywords" content="nora, spora, sporas, noraspora, supersenes, supersēnes, super, sēnes, maple garden, kļavas dārzs, svaigas sēnes, Trubenieki, Eco Fungus, Austersēnes, Sēņu laboratorija Spora, baravikas, gailenes, bekas, micēlijs, micēlija tapiņas, micēlija dībeļi, rozā austersēnes, austeres, pelēkās austersēnes, zilās austersēnes, lions mane, ežu dižadatene, reiši, reishi, shitake, šitake, šitaki, dzeltenās austersēnes, zeltainās austersēnes, zelta austersēnes, dāvana, dāvanas, hobijs, ekoloģisks, bioloģisks, bio, eko, dabīgs, latvijas sēnes" />
      </Helmet>
      <div className="hero-section">
        <video 
          autoPlay 
          muted 
          loop 
          className="video-background" 
          playsInline 
          disablePictureInPicture 
          controls={false}
        >
          <source src={videoFile} type="video/mp4" />
          {/* Your browser does not support the video tag. */}
        </video>
        <div className="hero-content">
          <h1>Nora Spora</h1>
          <p className='padding-20-bottom'>Sēņošanas prieks gan pilsētā, gan laukos!</p>
            <ButtonPrimary text="Iepirkties" onClick={(e) => handleVeikalsClick(e)} />
        </div>
      </div>
      <div className="container-no-padding" id="veikals">
        <div className="featured-section padding-40-top">
          <h2 className='light padding-20-bottom'>Sēņu audzēšanas komplekti</h2>
          <ProductGrid products={products} />
        </div>
      </div>
    </div>
  );
}

export default Home;
