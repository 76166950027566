import ButtonPrimary from "../style-components/ButtonPrimary";

function VariantForm({ variant_type, handleSubmit, variant, handleVariantChange, saveVariant, removeVariant, index }) {
  return (
    <div className="variant-container padding-20-top">
      {index !== -1 ? <h5 className="light">{index + 1}. {variant_type}: <b>{variant.variant_name}</b></h5> : <h5 className="light">Jauna variācija</h5>}
      <div className="form-group double">
        <div className="product-input-container">
          <label>Nosaukums</label>
          <input
            type="text"
            value={variant.variant_name}
            placeholder="Izmēra/krāsas nosaukums (S, M ,L)"
            onChange={(e) =>
              handleVariantChange(index !== -1 ? index : "new", "variant_name", e.target.value)
            }
          />
        </div>
        <div className="product-input-container">
          <label>Cena</label>
          <input
            type="number"
            value={variant.price}
            placeholder="Price"
            onChange={(e) =>
              handleVariantChange(index !== -1 ? index : "new", "price", e.target.value)
            }
          />
        </div>
      </div>
      <div className="form-group double">
        <div className="product-input-container">
          <label>Atlaides cena</label>
          <input
            type="number"
            placeholder="Discount Price"
            value={variant.discount_price}
            onChange={(e) =>
              handleVariantChange(index !== -1 ? index : "new", "discount_price", e.target.value)
            }
          />
        </div>
        <div className="product-input-container">
          <label>Daudzums noliktavā</label>
          <input
            type="number"
            placeholder="Count In Stock"
            value={variant.countInStock}
            onChange={(e) =>
              handleVariantChange(index !== -1 ? index : "new", "countInStock", e.target.value)
            }
          />
        </div>
      </div>
      <div className="form-group double">
        <div className="product-input-container">
          <label>Produkta SKU</label>
          <input
            type="text"
            placeholder="Produkta SKU"
            value={variant.sku}
            onChange={(e) =>
              handleVariantChange(index !== -1 ? index : "new", "sku", e.target.value)
            }
          />
        </div>
        <div className="product-input-container">
          <label>Svars (KG)</label>
          <input
            type="number"
            placeholder="Weight"
            value={variant.weight}
            onChange={(e) =>
              handleVariantChange(index !== -1 ? index : "new", "weight", e.target.value)
            }
          />
        </div>
      </div>
      <div className="form-group tripple">
        <div className="product-input-container">
          <label>Garums (cm)</label>
          <input
            type="number"
            placeholder="Length"
            value={variant.length}
            onChange={(e) =>
              handleVariantChange(index !== -1 ? index : "new", "length", e.target.value)
            }
          />
        </div>
        <div className="product-input-container">
          <label>Augstums (cm)</label>
          <input
            type="number"
            placeholder="Height"
            value={variant.height}
            onChange={(e) =>
              handleVariantChange(index !== -1 ? index : "new", "height", e.target.value)
            }
          />
        </div>
        <div className="product-input-container">
          <label>Platums (cm)</label>
          <input
            type="number"
            placeholder="Width"
            value={variant.width}
            onChange={(e) =>
              handleVariantChange(index !== -1 ? index : "new", "width", e.target.value)
            }
          />
        </div>
      </div>
      
      <div className="button-container padding-20-top"> 
        {index !== -1 ? 
        <div className="flex gap-10">
          <ButtonPrimary text="Dzēst izmēru" onClick={(e) => {
              e.preventDefault();
              removeVariant(index);
          }} />
          <ButtonPrimary text="Saglabāt" onClick={handleSubmit} />
        </div>
        :
        <ButtonPrimary text="Saglabāt izmēru" onClick={(e) => {
            e.preventDefault();
            saveVariant();
          }} />
        }
      </div>
    </div>
  );
}

export default VariantForm;

