import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getQuestions, reset } from '../features/questions/questionSlice';
import { toast } from 'react-toastify';
import Spinner from '../components/Spinner';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

function Questions() {
  const dispatch = useDispatch();
  const { questions, isLoading, isError, message } = useSelector((state) => state.question);

  const [questionOpen, setQuestionOpen] = useState(-1);
  const [questionCategories, setQuestionCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1100);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    if (questions) {
      // Extract unique categories from questions
      const categories = [...new Set(questions.map((question) => question.category))];
      setQuestionCategories(categories);
      setSelectedCategory(categories[0]);
    }

    return () => {
      dispatch(reset());
    };
  }, [questions, isLoading, isError, message, dispatch]);

  useEffect(() => {
    dispatch(getQuestions());
  }, [dispatch]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1100);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSelectQuestion = (index) => {
    if (questionOpen === index) {
      setQuestionOpen(-1);
      return;
    }
    setQuestionOpen(index);
  };

  if (isLoading || !questions || questions.length === 0) {
    return <Spinner />;
  }

  return (
    <div className="questions">
      <Helmet>
        <title>Nora Spora - Biežāk uzdotie jautājumi</title>
        <meta name="description" content="Atrodiet atbildes uz biežāk uzdotajiem jautājumiem par mūsu produktiem, pakalpojumiem un daudz ko citu." />
        <meta name="keywords" content="nora, spora, questions, FAQ, austersēnes, baravikas, gailenes, bekas, micēlijs, micēlija tapiņas, micēlija dībeļi, rozā austersēnes, austeres, pelēkās austersēnes, zilās austersēnes, lions mane, ežu dižadatene, reiši, reishi, shitake, šitake, šitaki, dzeltenās austersēnes, zeltainās austersēnes, zelta austersēnes, dāvana, dāvanas, hobijs, ekoloģisks, bioloģisks, bio, eko, dabīgs, latvijas sēnes" />
      </Helmet>
      <h2 className="light">Biežāk uzdotie jautājumi</h2>
      <div className="question-page">
        {!isMobile && (
          <div className="question-categories">
            <ul>
              {questionCategories.map((category) => (
                <li
                  onClick={() => setSelectedCategory(category)}
                  className={selectedCategory === category ? 'category selected' : 'category'}
                  key={category}
                >
                  {category}
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className="question-area">
          {questions
            .filter((question) => isMobile || question.category === selectedCategory)
            .map((question, index) => (
              <div key={question._id}>
                <div className={`question ${questionOpen === index ? "active" : ""}`} onClick={() => handleSelectQuestion(index)}>
                  <h5 className="light">{question.question}</h5>
                  <span className="switch-icon">
                    {questionOpen === index ? (
                      <FaAngleUp size={15} style={{ marginBottom: '-5px' }} />
                    ) : (
                      <FaAngleDown size={15} style={{ marginBottom: '-5px' }} />
                    )}
                  </span>
                </div>
                {questionOpen === index && (
                  <div className="answer">
                    <div dangerouslySetInnerHTML={{ __html: question.answer }} />
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default Questions;
