import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { processOrder, reset, getOrderPublic, resendEmail } from "../features/orders/orderSlice";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";

function OrderConfirmation() {
  const dispatch = useDispatch();
  const { order, isLoading, isSuccess, isError, message } = useSelector((state) => state.order);
  const { orderId } = useParams();

  const [emailSent, setEmailSent] = useState(false);


  // Save the initial cart state before placing the order
  useEffect(() => {
    const cart = localStorage.getItem('cart');
    if (cart) {
      localStorage.setItem('initialCart', cart);
    }
  }, []);

  useEffect(() => {
    // if (order) {
    //   if(!order.email_sent && !emailSent) {
    //     dispatch(resendEmail(order._id));
    //     setEmailSent(true);
    //   }
    // }

    return () => {
      dispatch(reset());
    };
  }, [order, isLoading, isSuccess, isError, message, dispatch]);

  useEffect(() => {
    dispatch(getOrderPublic(orderId));
  }, [dispatch, orderId]);

const getShippingPrice = (order) => {
  if(order.shipping_discount) {
    return order.shipping - order.shipping_discount;
  } else {
    return order.shipping;
  }
}

  if (isLoading || !order) {
    return <Spinner />;
  }

  const calculateOrderTotal = (order) => {
    let total = 0;
    for (const item of order.items) {
      total += item.variation.price * item.qty;
    }
    return total + order.shipping;
  }

  const formatPrice = (price) => {
    return (price / 100).toFixed(2) + '€';
  }

  return (
    <div className="order-confirmation padding-20-bottom">
      {order.orderStatus !== 'Failed' ? (
        <div className="success-order">
          {(order && order.status !== 'Failed') && (
            <>
              <h2 className='light'>Paldies par Jūsu pirkumu!</h2>
              <div className="flex column padding-10-bottom padding-10-top">
                <p className="padding-10-top">Pasūtījuma numurs: #{order.order_num ? order.order_num : order._id}</p>
                {/* <p className="padding-10-top">Summa: {order.price} €</p> */}
              </div>
              <table className="order-confirmation-table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Produkts</th>
                    <th>Skaits</th>
                    <th>Cena</th>
                  </tr>
                </thead>
                <tbody>
                  {order.items.map((item) => (
                    <tr key={item._id}>
                      <td className="confirmation-product-image">
                        {item.category !== 'omniva' && <a href={`/product/${item._id}`} target="_blank"> <img src={item.image.src} alt={item.title} /></a>}
                      </td>
                      <td className="confirmation-product-title"><a href={`/product/${item._id}`} target="_blank">{item.title}, {item.category}</a></td>
                      <td className="confirmation-product-qty">{item.category !== 'omniva' && item.qty}</td>
                      <td className="confirmation-product-price">
                          <span className={`${item.variants[0].discount_price ? 'discounted' : ''}`}>{formatPrice(item.variants[0].price)}</span>
                          {(item.variants[0].discount_price && item.variants[0].discount_price > 0) ? <span>{item.variants[0].discount_price && `${formatPrice(item.variants[0].discount_price)}`}</span> : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                    <tr>
                      <td colSpan="3">Piegādes maksa:</td>

                      <td>
                        <span className={`${(order.shipping_discount && order.shipping_discount > 0) ? 'discounted' : ''}`}>{formatPrice(order.shipping)}</span>
                        {(order.shipping_discount && order.shipping_discount > 0) ? <span>{formatPrice(getShippingPrice(order))}</span> : null}
                      </td>

                      {/* <td>
                        <span className={`${order.shipping_discount ? 'discounted' : ''}`}>{order.shipping.toFixed(2)}€</span>
                        <span>{order.shipping_discount && `${getShippingPrice(order).toFixed(2)}€`}</span>
                      </td> */}
                      {/* <td>
                        Piegādes maksa
                      </td> */}
                  </tr>
      
                  <tr>
                    <td colSpan="3">Kopā:</td>
                    <td>
                      {/* {order.total_paid.toFixed(2)}€ */}
                      <span className={`${order.total_paid !== calculateOrderTotal(order) ? 'discounted' : ''}`}>{formatPrice(calculateOrderTotal(order))}</span>
                      <span>{formatPrice(order.total_paid)}</span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </>
          )}
        </div>
      ) : (
        <div className="error-order">
          <h1>Kaut kas nogāja greizi</h1>
          <p>{message}</p>
        </div>
      )}
    </div>
  );
}

export default OrderConfirmation;
