// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDgjVJmLWYHO3iDccbGDyvUDFL94uwQU-0",
  authDomain: "noraspora-c6600.firebaseapp.com",
  projectId: "noraspora-c6600",
  storageBucket: "noraspora-c6600.appspot.com",
  messagingSenderId: "388534522317",
  appId: "1:388534522317:web:17c3356f1504d15b26b484",
  measurementId: "G-YV70LPDR5C"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);