import eikeni from '../img/eikeni.jpg'
import { Helmet } from 'react-helmet'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTexts } from '../features/texts/textSlice'
import Spinner from '../components/Spinner'

function AboutUs() {

  const dispatch = useDispatch();

    const { texts, isLoading, isError, isSuccess, message } = useSelector((state) => state.texts);

    useEffect(() => {
        dispatch(getTexts());
    }, []);

    const getValueByTag = (tag) => {
      const textObject = texts.find(text => text.tag === tag);
      return textObject ? { __html: textObject.value } : { __html: '' };
    }

    if(isLoading){
      return <Spinner />
    }

  return (
    <div className='about-us padding-10-bottom'>
      <Helmet>
        <title>Nora Spora - Par mums</title>
        <meta name="description" content="Nora Spora piedāvā plašu klāstu sēņu audzēšanas komplektu, tostarp rozā un pelēkās austersēnes, ežu dižadatenes, gļotainās zvīņenes un drīzumā arī citas." />
        <meta name="keywords" content="nora, spora, sporas, noraspora, supersenes, supersēnes, super, sēnes, maple garden, kļavas dārzs, svaigas sēnes, Trubenieki, Eco Fungus, Austersēnes, Sēņu laboratorija Spora, baravikas, gailenes, bekas, micēlijs, micēlija tapiņas, micēlija dībeļi, rozā austersēnes, austeres, pelēkās austersēnes, zilās austersēnes, lions mane, ežu dižadatene, reiši, reishi, shitake, šitake, šitaki, dzeltenās austersēnes, zeltainās austersēnes, zelta austersēnes, dāvana, dāvanas, hobijs, ekoloģisks, bioloģisks, bio, eko, dabīgs, latvijas sēnes" />
      </Helmet>
      <div className="two-column-flex">
        <div className="left">
          <div className="about-us-media">
            <img src={eikeni} alt="Eikeni" />
          </div>
        </div>
        <div className="right">
          <div className="about-us-content">
            <h2 className='light padding-20-bottom'>Par mums</h2>
            <div dangerouslySetInnerHTML={getValueByTag('par-mums')}></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
