import React from 'react';
import { Link } from 'react-router-dom';
import placeholder_image from '../img/placeholder-image.jpeg';
import { IoCartOutline } from "react-icons/io5";
import { useDispatch } from 'react-redux';
import { addToCart } from '../features/products/productSlice';
import { removeCoupon } from '../features/products/productSlice';
import { toast } from 'react-toastify';

function ProductCard({ product, admin = false }) {
  const dispatch = useDispatch();

  const handleAddToCart = () => {
    dispatch(addToCart({
      ...product,
      variant: product.variants[0]
    }));
    dispatch(removeCoupon());
  }

  const getPrice = () => {
    // Helper function to format price
    const formatPrice = (price) => (price / 100).toFixed(2);
  
    // If there are multiple variants
    if (product.variants.length > 1) {
      // Get the cheapest and most expensive variant prices
      const cheapest_product = product.variants.reduce((prev, current) => prev.price < current.price ? prev : current);
      const expensive_product = product.variants.reduce((prev, current) => prev.price > current.price ? prev : current);
  
      const cheapest = cheapest_product.discount_price ? cheapest_product.discount_price : cheapest_product.price;
      const expensive = expensive_product.discount_price ? expensive_product.discount_price : expensive_product.price;
  
      // If the cheapest and most expensive prices are the same, return a single price
      if (cheapest === expensive) {
        return `${formatPrice(cheapest)}€`;
      } else {
        return `${formatPrice(cheapest)}-${formatPrice(expensive)}€`;
      }
  
    // If there is only one variant
    } else {
      const price = product.variants[0].discount_price ? product.variants[0].discount_price : product.variants[0].price;
      return `${formatPrice(price)}€`;
    }
  }

  const hasDiscount = () => {
    return product.variants.some(variant => variant.discount_price);
  }

  const outOfStock = () => {
    return product.variants.every(variant => variant.countInStock < 1);
  }

  if(!product || !product.variants) {
    return null
  }

  return (
    <div className="product-grid-card">
      <div className="card-top">
        <Link to={`/product/${product._id}`}>
          <div className="product-card-image-container">
            {outOfStock() && (
              <div className="out-of-stock">Nav noliktavā</div>
            )}
            {(!outOfStock() && hasDiscount())  && (
              <div className="discount">Atlaide</div>
            )}
            <img src={product.image && product.image.src ? product.image.src : placeholder_image} alt="Product" className="product-image" />
          </div>
        </Link>
        <div className="padding-10-bottom"></div>
        <Link to={`/product/${product._id}`}>
          <h3 className="product-title">
            {product.title || "Produkta nosaukums"}
          </h3>
        </Link>
        <Link to={`/product/${product._id}`}>
          <p className="product-description">
            {product.short_description || "Lorem ipsum this and that was there and this that."}
          </p>
        </Link>
      </div>
      <div className="card-bottom">
        <div className="product-actions">
          <p className={`product-price`}>
            {!admin &&
              <>
                <span>{getPrice()}</span>
              </>
            }
          </p>
          {!admin && (
            <button 
              className={`add-to-cart-button ${outOfStock() ? 'out-of-stock' : ''}`} 
              disabled={outOfStock()} 
              onClick={handleAddToCart}
            >
              <IoCartOutline size={25}/>
            </button>
          )}  
        </div>
      </div>
    </div>
  );
}

export default ProductCard;
