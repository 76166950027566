import React from 'react'
import { Link } from 'react-router-dom';

function MenuItem({text, link, onClick}) {
  return (
    <Link onClick={onClick} to={link} className='header-menu-item'>{text}</Link>
  )
}

export default MenuItem
