import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { getCoupons, deleteCoupon, reset } from '../../features/coupons/couponSlice'
import { useDispatch } from 'react-redux'
import { FaCheck, FaTimes } from "react-icons/fa";
import ButtonPrimary from '../../components/style-components/ButtonPrimary'
import { useNavigate } from 'react-router-dom'


function AdminEditCoupons() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { coupons, isLoading, isSuccess, isError, message } = useSelector(state => state.coupon)

    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
      if (!user) {
        navigate('/');
      }
    }, [user, navigate]);

    useEffect(() => {
        if(isError){
            toast.error(message)
        }

        return () => {
            dispatch(reset())
        }
    }, [coupons, isLoading, isError, isSuccess, message])

    useEffect(() => {
        dispatch(getCoupons())
    }, [])

  return (
    <div className='edit-coupons'>
        <div className="flex padding-20-bottom">
            <ButtonPrimary text="Pievienot jaunu kuponu" onClick={() => navigate("/edit-coupons/new")}/>
        </div>
        
        <table className="coupon-table">
            <thead>
                <tr>
                    <th style={{width:"20%", overflow:"hidden"}} className='coupon-code'>Kods</th>
                    <th style={{width:"15%"}} className='coupon-code'>Atlaide</th>
                    <th style={{width:"20%"}} className='coupon-code'>Bezmaksas piegāde</th>
                    <th style={{width:"15%"}}className='coupon-code'>Derīgs līdz </th>
                    <th style={{width:"30%"}}></th>
                </tr>
            </thead>
            <tbody>
                {coupons && coupons.map(coupon => (
                    <tr key={coupon._id}>
                        <td style={{width:"20%", overflow:"hidden"}} className='coupon-code'>{coupon.code}</td>
                        <td className='coupon-code'>{coupon.discountAmount ? `€${(coupon.discountAmount / 100).toFixed(2)}` : coupon.discountPercentage ? `${coupon.discountPercentage}%` : 'Bezmaksas piegāde'}</td>
                        <td className='coupon-code delivery'>{coupon.delivery ? <FaCheck /> : <FaTimes />}</td>
                        <td className='coupon-code'>
                            {!coupon.active || new Date(coupon.expiryDate).getTime() < new Date().getTime() ? "Nederīgs" : coupon.expiryDate.split('T')[0]}
                        </td>                        
                        <td className='coupon-code flex center gap-10'>
                           <ButtonPrimary text="Rediģēt" onClick={()=>navigate(`/edit-coupons/${coupon._id}`)}/>
                           <ButtonPrimary text="Dzēst" onClick={()=>dispatch(deleteCoupon(coupon._id))}/>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>


     
    </div>
  )
}

export default AdminEditCoupons
