import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

function NotFound() {
  const location = useLocation();

  // If the current path is not /contacts, redirect to the home page
  if (location.pathname !== '/contacts') {
    return <Navigate to="/" />;
  }

  return (
    <div>
      {/* Optionally add some content or styling here */}
    </div>
  );
}

export default NotFound;
