import { useEffect, useState } from 'react';
import ButtonPrimary from '../components/style-components/ButtonPrimary';
import contact_bg from '../img/contact_bg.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { contactUs } from '../features/orders/orderSlice';
import Spinner from '../components/Spinner';
import { Helmet } from 'react-helmet';

function Contacts() {
  const dispatch = useDispatch();

  const { isLoading, isSuccess } = useSelector(state => state.order);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = () => {

    if(!formData.name || !formData.email || !formData.message) {
      alert('Lūdzu aizpildiet visus laukus');
      return;
    }
    dispatch(contactUs(formData));
  };

  useEffect(() => {
    if(isSuccess) {
      setFormData({
        name: '',
        email: '',
        message: ''
      });
    }
  }, [isSuccess]);

  if(isLoading) return <Spinner />;

  return (
    <div className='contacts' style={{background: `url(${contact_bg})`, backgroundSize:"cover", backgroundPosition:"top center"}}>
          <Helmet>
        <title>Nora Spora - Kontakti</title>
        <meta name="description" content="Sazinies ar Nora Spora! Raksti savu sēņu jautājumu, nosūti ziņu vai sazinies ar mums caur e-pastu, Instagram vai Facebook." />
        <meta name="keywords" content="Nora Spora, sēņu audzēšana, sēņu jautājumi, kontakti, sazināties, ziņa, e-pasts, Instagram, Facebook" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <div className="contacts-container">
        <div className="contact-card" >
          <h2 className="light">Raksti savu sēņu jautājumu šeit</h2>
          <form>
              <div className="form-group">
                <input 
                  type="text" 
                  className="basic-input" 
                  placeholder='Vārds' 
                  name="name" 
                  value={formData.name} 
                  onChange={handleChange} 
                />
                <input 
                  type="text" 
                  className="basic-input" 
                  placeholder='E-pasts' 
                  name="email" 
                  value={formData.email} 
                  onChange={handleChange} 
                />
              </div>
              <textarea 
                name="message" 
                id="" 
                cols="30" 
                rows="10" 
                className="basic-input" 
                placeholder='Ziņa' 
                value={formData.message} 
                onChange={handleChange} 
              />

              <div className="flex flex-start">
                <ButtonPrimary text='Nosūtīt' type="dark" onClick={handleSubmit}/>
              </div>
             
          </form>
          
          <p>
            Vai sazinies ar mums caur e-pastu <a href="mailto:info@noraspora.lv" target='_blank' rel="noreferrer">info@noraspora.lv</a>, <a href="https://www.instagram.com/nora.spora/" target='_blank' rel="noreferrer">Instagram</a> vai <a href="https://www.facebook.com/noraspora.lv/" target='_blank' rel="noreferrer">Facebook</a>!
          </p>
        </div>
      </div>
    </div>
  );
}

export default Contacts;