import React from 'react';
import ButtonPrimary from './style-components/ButtonPrimary';

function Confirmation({ message, onConfirm, onCancel }) {
  return (
    <div className='confirmation-screen'>
      <div className='confirmation-box'>
        <h4 className='light'>{message}</h4>
        <div className='confirmation-buttons'>
          <ButtonPrimary text='Jā' onClick={onConfirm} />
          <ButtonPrimary text='Nē' onClick={onCancel} />
        </div>
      </div>
    </div>
  );
}

export default Confirmation;
