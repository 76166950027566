import axios from 'axios'
const API_URL = '/texts/'


// Add text
const addText = async (data, token) => {
  const config = {
    headers: {
        Authorization: `Bearer ${token}`,
    },
};
  const response = await axios.post(API_URL, data, config)

  return response.data
}

// Get texts
const getTexts = async (token) => {
  const response = await axios.get(API_URL)
  return response.data
}

const textService = {
  getTexts,
  addText,
}

export default textService