import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import textService from './textService'

const initialState = {
  texts: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Get texts
export const getTexts = createAsyncThunk('texts/', async (_, thunkAPI) => {
  try {
    return await textService.getTexts(thunkAPI)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const addText = createAsyncThunk('texts/create', async (data, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token;

        return await textService.addText(data, token)
      } catch (error) {
        const message =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
})

export const textSlice = createSlice({
  name: 'texts',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTexts.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getTexts.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.texts = action.payload
      })
      .addCase(getTexts.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.texts = []
      })
      .addCase(addText.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addText.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.texts = state.texts.map(text => 
          text._id === action.payload._id ? action.payload : text
        );
      })
      .addCase(addText.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.texts = []
      })
  },
})

export const { reset } = textSlice.actions
export default textSlice.reducer