import React from 'react'

function StyleGuide() {
  return (
    <div className="container" style={{paddingTop: "15%"}}>
        <h1>Style Guide</h1>

        <section className="section">
            <h2>Colors</h2>
        </section>

      <section className="section">
        <h2>Typography</h2>
        <h1>H1 Heading</h1>
        <h2>H2 Heading</h2>
        <h3 className="inter">H3 Heading</h3>
        <h4 className="inter">H4 Heading</h4>
        <h5 className="inter">H5 Heading</h5>
        <p className="s">Small paragraph</p>
        <p className="r">Regular paragraph</p>
        <p className="l">Large paragraph</p>
        <p className="xl">Extra Large paragraph</p>
      </section>

    </div>
  )
}

export default StyleGuide