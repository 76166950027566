import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { IoCartOutline } from 'react-icons/io5';

function CartCounter({closeMenu}) {
  const [cartItems, setCartItems] = useState([]);

  const { cart } = useSelector((state) => state.product);

  useEffect(() => {
    if (cart) {
      setCartItems(cart.length);
    }
  }, [cart]);

  return (
    <div className="cart-counter">
      <Link to="/cart" onClick={closeMenu}>
        <span>{cartItems}</span>
        <IoCartOutline className='cart-icon' style={{marginBottom: "-4px", marginLeft: "10px"}} size={"1.5rem"}/>
      </Link>
    </div>
  );
}

export default CartCounter;
