import { useState } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

function ProductMedia({ productImages }) {
  const [activeImage, setActiveImage] = useState(0);

  const handleNextImage = () => {
    setActiveImage((prevActiveImage) => (prevActiveImage + 1) % productImages.length);
  };

  const handlePreviousImage = () => {
    setActiveImage((prevActiveImage) => (prevActiveImage - 1 + productImages.length) % productImages.length);
  };

  return (
    <div className="product-media">
      <div className="active-image no-select">
        <div className="arrow-container left" onClick={handlePreviousImage}>
          <div className="arrow">
            <FaAngleLeft size={25} />
          </div>
        </div>
        <div className="arrow-container right" onClick={handleNextImage}>
          <div className="arrow">
            <FaAngleRight size={25}  />
          </div>
        </div>
        <img src={productImages[activeImage]?.src || null} alt={"product.title"} className='product-active-image' />
      </div>
      <div className="image-gallery no-select">
        {productImages.map((image, index) => (
          <img
            src={image.src}
            alt={"product.title"}
            key={index}
            className={`product-image ${activeImage === index ? 'active' : ''}`}
            onClick={() => setActiveImage(index)}
          />
        ))}
      </div>
    </div>
  );
}

export default ProductMedia;