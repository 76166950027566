import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getProductById, reset, addToCart } from '../features/products/productSlice'
import { removeCoupon } from '../features/products/productSlice'
import { toast } from 'react-toastify'
import Spinner from '../components/Spinner'
import { useParams } from 'react-router-dom'
import ProductContent from '../components/ProductContent'
import ProductMedia from '../components/ProductMedia'
import { Helmet } from 'react-helmet'


function SingleProduct() {

  const dispatch = useDispatch()

  const { productId } = useParams()

  const { product, isLoading, isError, message } = useSelector(
    (state) => state.product
  )

  const [productImages, setProductImages] = useState([])

  useEffect(() => {
    if(isError) {
      toast.error(message)
    }

    if(product){
      setProductImages([product.image, ...product.images])
    }

    return () => {
        dispatch(reset());
    };
  }, [product, isLoading, isError, message, dispatch]);


  useEffect(() => {
    dispatch(getProductById(productId))
  }, [dispatch, productId]);

  const handleAddToCart = (product) => {
    dispatch(addToCart(product));
    dispatch(removeCoupon());
  }

  if(isLoading || !product || product._id !== productId) {
    return <Spinner />
  }

  return (
    <div className="single-product-page padding-20-bottom">
      <Helmet>
        <title>{`Nora Spora - ${product.title}`}</title>
        <meta name="description" content={`${product.title} - Nora Spora piedāvā plašu klāstu sēņu audzēšanas komplektu, tostarp rozā un pelēkās austersēnes, ežu dižadatenes, gļotainās zvīņenes un drīzumā arī citas.`} />
        <meta name="keywords" content={`nora, spora, ${product.title}, ${product.category}, austersēnes, baravikas, gailenes, bekas, micēlijs, micēlija tapiņas, micēlija dībeļi, rozā austersēnes, austeres, pelēkās austersēnes, zilās austersēnes, lions mane, ežu dižadatene, reiši, reishi, shitake, šitake, šitaki, dzeltenās austersēnes, zeltainās austersēnes, zelta austersēnes, dāvana, dāvanas, hobijs, ekoloģisks, bioloģisks, bio, eko, dabīgs, latvijas sēnes`} />
      </Helmet>
      <div className='single-product padding-20-top '>
        <ProductMedia productImages={productImages}/>
        <ProductContent product={product} handleAddToCart={handleAddToCart}/>
      </div>
    </div>
    
  )
}

export default SingleProduct
